import {
  SET_ORDERS,
  SET_NEW_ORDERS,
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,
  ACCEPT_ORDER_REQUEST,
  ACCEPT_ORDER_SUCCESS,
  ACCEPT_ORDER_ERROR,
  REJECT_ORDER_REQUEST,
  REJECT_ORDER_SUCCESS,
  REJECT_ORDER_ERROR,
  RESET_ORDERS,
  RESET_ORDERS_FILTERS,
  COMPLETE_ORDER_REQUEST,
  COMPLETE_ORDER_SUCCESS,
  COMPLETE_ORDER_ERROR,

  SET_ORDERS_SEARCH_VALUE,
  SET_ORDERS_VIEW,
  SET_ORDERS_GROUP,
  SET_ACTIVE_ORDERS_FILTER,
  SET_IS_FILTER,
  SET_ORDERS_FILTER,
  SET_ORDERS_GRID_SORT_KEY,
  SET_ORDERS_GRID_SORTING_ORDER,
  CLEAR_ORDER_LOADING,

  DISPATCH_ORDERS_STARTED,
  DISPATCH_ORDERS_ENDED,

  EDIT_DISPATCH_SETTINGS_STARTED,
  EDIT_DISPATCH_SETTINGS_ENDED,

  GET_DRIVERS_REQUEST,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_ERROR,

  GET_DISPATCH_CONFIG_REQUEST,
  GET_DISPATCH_CONFIG_SUCCESS,
  GET_DISPATCH_CONFIG_ERROR,

  UNDISPATCH_ALL_STARTED,
  UNDISPATCH_ALL_ENDED,

  UNDISPATCH_STARTED,
  UNDISPATCH_ENDED,

  SET_BUSY_STARTED,
  SET_BUSY_ENDED,

  SET_CLOSED_STARTED,
  SET_CLOSED_ENDED,

  SET_BUSY_SETTINGS,
  SET_CLOSED_SETTINGS,

  GET_REASONS_REQUEST,
  GET_REASONS_SUCCESS,
  GET_REASONS_ERROR,

  ADD_REASON_STARTED,
  ADD_REASON_ENDED,

  EDIT_REASON_STARTED,
  EDIT_REASON_ENDED,

  DELETE_REASON_STARTED,
  DELETE_REASON_ENDED

} from "../actions/OrderActions";

const initialState = {
  addReasonLoading: false,
  editReasonLoading: false,
  deleteReasonLoading: false,
  reasonsLoading: false,
  reasons: [],
  closedSettings: null,
  busySettings: null,
  closeLoading: false,
  busyLoading: false,
  dispatchLoading: false,
  undispatchLoading: false,
  undispatchAllLoading: false,
  ordersToBeDispatched: [],
  ordersToBeUnDispatched: [],
  dispatchSettingsLoading: false,
  driversLoading: false,
  drivers: [],
  orders: [],
  newOrders: [],
  order: null,
  config: null,
  getOrdersLoading: false,
  getOrderLoading: false,
  cancelOrderLoading: false,
  acceptOrderLoading: false,
  rejectOrderLoading: false,
  completeOrderLoading: false,

  //filters and selected data
  ordersView: "grid",
  searchValue: "",
  ordersGroup: "",
  activeOrdersFilter: "all-active-orders",
  isFilter: false,
  ordersFilter: {
    orderDate: { from: null, to: null },
    orderTime: { from: null, to: null },
    dueDate: { from: null, to: null },
    dueTime: { from: null, to: null },
    orderType: [],
    paymentMethod: [],
    allergen: [],
    status: [],
  },
  ordersGridSortKey: "createdAt",
  ordersGridSortingOrder: "desc"
};

function order(state = initialState, action) {
  switch (action.type) {
    case DELETE_REASON_ENDED:
      return Object.assign({}, state, {
        deleteReasonLoading: false
      })
    case DELETE_REASON_STARTED:
      return Object.assign({}, state, {
        deleteReasonLoading: true
      })
    case EDIT_REASON_ENDED:
      return Object.assign({}, state, {
        editReasonLoading: false
      })
    case EDIT_REASON_STARTED:
      return Object.assign({}, state, {
        editReasonLoading: true
      })
    case ADD_REASON_ENDED:
      return Object.assign({}, state, {
        addReasonLoading: false
      })
    case ADD_REASON_STARTED:
      return Object.assign({}, state, {
        addReasonLoading: true
      })
    case GET_REASONS_ERROR:
      return Object.assign({}, state, {
        reasonsLoading: false,
        reasons: []
      })
    case GET_REASONS_SUCCESS:
      return Object.assign({}, state, {
        reasonsLoading: false,
        reasons: action.payload
      })
    case GET_REASONS_REQUEST:
      return Object.assign({}, state, {
        reasonsLoading: true
      })
    case SET_CLOSED_SETTINGS:
      return Object.assign({}, state, {
        closedSettings: action.payload
      })
    case SET_BUSY_SETTINGS:
      return Object.assign({}, state, {
        busySettings: action.payload
      })
    case SET_CLOSED_ENDED:
      return Object.assign({}, state, {
        closeLoading: false
      })
    case SET_CLOSED_STARTED:
      return Object.assign({}, state, {
        closeLoading: true
      })
    case SET_BUSY_ENDED:
      return Object.assign({}, state, {
        busyLoading: false
      })
    case SET_BUSY_STARTED:
      return Object.assign({}, state, {
        busyLoading: true
      })
    case UNDISPATCH_ENDED:
      return Object.assign({}, state, {
        undispatchLoading: false,
        ordersToBeUnDispatched: [...state?.ordersToBeUnDispatched]?.filter((order) => {
          return order?.uuid !== action?.payload?.uuid
        })
      })
    case UNDISPATCH_STARTED:
      return Object.assign({}, state, {
        undispatchLoading: true,
        ordersToBeUnDispatched: [...state?.ordersToBeUnDispatched, action?.payload?.ouuid]
      })
    case UNDISPATCH_ALL_ENDED:
      return Object.assign({}, state, {
        undispatchAllLoading: false,
      })
    case UNDISPATCH_ALL_STARTED:
      return Object.assign({}, state, {
        undispatchAllLoading: true
      })
    case GET_DISPATCH_CONFIG_ERROR:
      return Object.assign({}, state, {
        dispatchSettingsLoading: false,
        config: null
      })
    case GET_DISPATCH_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        dispatchSettingsLoading: false,
        config: action.payload
      })
    case GET_DISPATCH_CONFIG_REQUEST:
      return Object.assign({}, state, {
        dispatchSettingsLoading: true
      })
    case GET_DRIVERS_ERROR:
      return Object.assign({}, state, {
        driversLoading: false,
        drivers: []
      })
    case GET_DRIVERS_SUCCESS:
      return Object.assign({}, state, {
        driversLoading: false,
        drivers: action.payload
      })
    case GET_DRIVERS_REQUEST:
      return Object.assign({}, state, {
        driversLoading: true
      })
    case EDIT_DISPATCH_SETTINGS_ENDED:
      return Object.assign({}, state, {
        dispatchSettingsLoading: false
      })
    case EDIT_DISPATCH_SETTINGS_STARTED:
      return Object.assign({}, state, {
        dispatchSettingsLoading: true
      })
    case DISPATCH_ORDERS_ENDED:
      return Object.assign({}, state, {
        dispatchLoading: false
      })
    case DISPATCH_ORDERS_STARTED:
      return Object.assign({}, state, {
        dispatchLoading: true
      })
    case CLEAR_ORDER_LOADING:
      return Object.assign({}, state, {
        getOrdersLoading: false,
        getOrderLoading: false,
        cancelOrderLoading: false,
        acceptOrderLoading: false,
        rejectOrderLoading: false,
        completeOrderLoading: false,
        dispatchLoading: false,
        undispatchLoading: false,
        undispatchAllLoading: false,
        dispatchSettingsLoading: false,
        driversLoading: false
      })
    case SET_ORDERS:
      return Object.assign({}, state, {
        orders: action?.payload?.data,
      });
    case SET_NEW_ORDERS:
      return Object.assign({}, state, {
        newOrders: action?.payload?.value,
     });
    case GET_ORDERS_REQUEST:
      return Object.assign({}, state, {
        getOrdersLoading: true,
      });
    case GET_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        getOrdersLoading: false,
        orders: action?.payload?.orders,
      });
    case GET_ORDERS_ERROR:
      return Object.assign({}, state, {
        getOrdersLoading: false,
        orders: [],
      });
    case GET_ORDER_REQUEST:
      return Object.assign({}, state, {
        getOrderLoading: true,
      });
    case GET_ORDER_SUCCESS:
      return Object.assign({}, state, {
        getOrderLoading: false,
        order: action?.payload?.order,
      });
    case GET_ORDER_ERROR:
      return Object.assign({}, state, {
        getOrderLoading: false,
        order: null,
      });
    case CANCEL_ORDER_REQUEST:
      return Object.assign({}, state, {
        cancelOrderLoading: true,
      });
    case CANCEL_ORDER_SUCCESS:
      return Object.assign({}, state, {
        cancelOrderLoading: false,
        orders: state.orders.map((order) => {
          if (order.uuid === action.payload.ouuid) {
            return {
              ...order,
              statusKey: "canceled",
            };
          }
          return order;
        }),
        order:
          state?.order?.uuid === action.payload.ouuid
            ? { ...state?.order, statusKey: "canceled" }
            : state?.order,
      });
    case CANCEL_ORDER_ERROR:
      return Object.assign({}, state, {
        cancelOrderLoading: false,
      });
    case ACCEPT_ORDER_REQUEST:
      return Object.assign({}, state, {
        acceptOrderLoading: true,
      });
    case ACCEPT_ORDER_SUCCESS:
      return Object.assign({}, state, {
        acceptOrderLoading: false,
        orders: state.orders.map((order) => {
          if (order.uuid === action.payload.ouuid) {
            return {
              ...order,
              statusKey: "accepted",
            };
          }
          return order;
        }),
        order:
          state?.order?.uuid === action.payload.ouuid
            ? { ...state?.order, statusKey: "accepted" }
            : state?.order,
      });
    case ACCEPT_ORDER_ERROR:
      return Object.assign({}, state, {
        acceptOrderLoading: false,
      });
    case REJECT_ORDER_REQUEST:
      return Object.assign({}, state, {
        rejectOrderLoading: true,
      });
    case REJECT_ORDER_SUCCESS:
      return Object.assign({}, state, {
        rejectOrderLoading: false,
        orders: state.orders.map((order) => {
          if (order.uuid === action.payload.ouuid) {
            return {
              ...order,
              statusKey: "rejected",
            };
          }
          return order;
        }),
        order:
          state?.order?.uuid === action.payload.ouuid
            ? { ...state?.order, statusKey: "rejected" }
            : state?.order,
      });
    case REJECT_ORDER_ERROR:
      return Object.assign({}, state, {
        rejectOrderLoading: false,
      });
    case COMPLETE_ORDER_REQUEST:
      return Object.assign({}, state, {
        completeOrderLoading: true,
      });
    case COMPLETE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        completeOrderLoading: false,
        orders: state.orders.map((order) => {
          if (order.uuid === action.payload.ouuid) {
            return {
              ...order,
              statusKey: order?.type === "delivery" ? "delivered" : "picked-up",
            };
          }
          return order;
        }),
        order:
          state?.order?.uuid === action.payload.ouuid
            ? { ...state?.order, statusKey: state?.order?.type === "delivery" ? "delivered" : "picked-up" }
            : state?.order,
      });
    case COMPLETE_ORDER_ERROR:
      return Object.assign({}, state, {
        completeOrderLoading: false,
      });
    case RESET_ORDERS:
      return Object.assign({}, state, {
        orders: [],    
        order: null,
        getOrdersLoading: false,
        getOrderLoading: false,
        cancelOrderLoading: false,
        acceptOrderLoading: false,
        rejectOrderLoading: false,
        completeOrderLoading: false,
      });
     case RESET_ORDERS_FILTERS:
        return Object.assign({}, state, {
          searchValue: "",
          ordersGroup: "",
          activeOrdersFilter: "all-active-orders",
          isFilter: false,
          ordersFilter: {
            orderDate: { from: null, to: null },
            orderTime: { from: null, to: null },
            dueDate: { from: null, to: null },
            dueTime: { from: null, to: null },
            orderType: [],
            paymentMethod: [],
            allergen: [],
            status: [],
          },
          ordersGridSortKey: "createdAt",
          ordersGridSortingOrder: "desc"
        });
    case SET_ORDERS_SEARCH_VALUE:
      return Object.assign({}, state, {
        searchValue: action?.payload?.value
      });
    case SET_ORDERS_VIEW:
      return Object.assign({}, state, {
        ordersView: action?.payload?.value
      });
    case SET_ORDERS_GROUP:
      return Object.assign({}, state, {
        ordersGroup: action?.payload?.value
      });
    case SET_IS_FILTER:
      return Object.assign({}, state, {
        isFilter: action?.payload?.value
      });
    case SET_ACTIVE_ORDERS_FILTER:
      return Object.assign({}, state, {
        activeOrdersFilter: action?.payload?.value
      });
    case SET_ORDERS_FILTER:
      return Object.assign({}, state, {
        ordersFilter: action?.payload?.value
      });
    case SET_ORDERS_GRID_SORT_KEY:
      return Object.assign({}, state, {
        ordersGridSortKey: action?.payload?.value
      });
    case SET_ORDERS_GRID_SORTING_ORDER:
      return Object.assign({}, state, {
        ordersGridSortingOrder: action?.payload?.value
      })
    default:
      return state;
  }
}
export default order;
