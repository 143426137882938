import React, { lazy, useEffect } from 'react'
import LayoutActions from '../../redux/actions/LayoutActions'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'
import UserRoute from '../../routers/UserRoute'
import { connect } from 'react-redux'
import { Box } from '@mui/material'

const components = {
    stockUom: lazy(() => import('../../pages/stock/Uom')),
    stockItems: lazy(() => import('../../pages/stock/StockItems')),
    stockGroups: lazy(() => import('../../pages/stock/StockGroups')),
    stockCategories: lazy(() => import('../../pages/stock/StockCategories')),
    stockRecipes: lazy(() => import('../../pages/stock/StockRecipes')),
};
function StockLayout({
    activeTab,
    setPathCheck,
    permission,
    reformat
}) {
    const access = reformat(permission.subPage)
        .sort((a, b) => {
            // Check if either 'order' is null
            if (a.order === null && b.order === null) {
                return 0; // Keep the order unchanged if both are null
            } else if (a.order === null) {
                return 1; // Place 'a' (null) after 'b' (non-null)
            } else if (b.order === null) {
                return -1; // Place 'b' (null) after 'a' (non-null)
            }
            // Sort non-null 'order' values normally
            return a.order - b.order;
        });
    useEffect(() => {
        setPathCheck(permission.route)
    }, [window.location.pathname])
    return (
        <div>
            <Box>
                <Switch>
                    <Route exact path={permission.route} render={() => <Redirect to={access[0]?.route} />} />
                    {access.map((permission2, i) =>
                        <UserRoute key={i} exact path={permission2.route} component={components[permission2.key]} permission={permission2} />
                    )}
                    <Redirect from="*" to="/not-found" />
                </Switch>
            </Box>
        </div>
    )
}
const mapStateToProps = (state) => ({
    activeTab: state.layout.stockActiveTab
})
const mapDispatchToProps = (dispatch) => ({
    setPathCheck: (data) => dispatch(LayoutActions.setPathCheck(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockLayout))