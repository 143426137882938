//import axios from 'axios'
import { PrivateApiCall, PrivateApiCallMessaging, PublicApiCall } from "./interceptors";
import Compressor from 'compressorjs';

const errorCatch = (error) => {
  console.log("ERROR API CALL", error, error?.response);
  if (error.response) {
    if (error.reponse?.data) {
      return error.response?.data;
    }
    return error.response;
  } else {
    return error;
  }
};

/** ******************************************************************************************* */
/** ************************************** AUTH *********************************************** */
/** ******************************************************************************************* */
async function login(data) {
  return await PublicApiCall.post("/auth/loginAdmin", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function forgotPass(uuid, data) {
  return await PrivateApiCall.post(`/admin/users/${uuid}/resetPassword`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function otp(data) {
  return await PrivateApiCall.post(`/auth/otp`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function verifyOTP(data) {
  return await PrivateApiCall.post(`/auth/verifyAdmin`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** ME-PROFILE ***************************************** */
/** ******************************************************************************************* */
async function getMeProfile() {
  return await PrivateApiCall.get("/admin/me")
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editMeProfile(data) {
  return await PrivateApiCall.put(`/admin/me`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** ADMIN-PROFILES ************************************* */
/** ******************************************************************************************* */
async function getAdminProfiles() {
  return await PrivateApiCall.get("/admin/admin-profiles")
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addAdminProfile(data) {
  return await PrivateApiCall.post("/admin/admin-profile", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editAdminProfile(data, uuid) {
  return await PrivateApiCall.put(`/admin/admin-profile/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteAdminProfile(uuid) {
  return await PrivateApiCall.delete(`/admin/admin-profile/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** RESTAURANT ***************************************** */
/** ******************************************************************************************* */
async function getRestaurants() {
  return await PrivateApiCall.get("/admin/restaurant")
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addRestaurant(data) {
  return await PrivateApiCall.post("/admin/restaurant", data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editRestaurant(data, uuid) {
  return await PrivateApiCall.put(`/admin/restaurant/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteRestaurant(uuid) {
  return await PrivateApiCall.delete(`/admin/restaurant/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** BRANCHES ******************************************* */
/** ******************************************************************************************* */
async function getBranches(ruuid) {
  return await PrivateApiCall.get(`/admin/branch/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addBranch(ruuid, data) {
  return await PrivateApiCall.post(`/admin/branch/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editBranch(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/branch/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteBranch(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/branch/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getBranchesTableOrdering(ruuid) {
  return await PrivateApiCall.get(`/admin/branch/tableOrdering/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getBranchesLoyalty(ruuid) {
  return await PrivateApiCall.get(`/admin/branch/loyalty/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getBranchesQRMenu(ruuid) {
  return await PrivateApiCall.get(`/admin/branch/qrMenu/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getBranchesFloorSetup(ruuid) {
  return await PrivateApiCall.get(`/admin/branch/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}


async function getBranchesOnlineDelivery(ruuid) {
  return await PrivateApiCall.get(`/admin/branch/onlineDelivery/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ***************************************************************************************** */
/** ************************************* CATEGORY ***************************************** */
/** **************************************************************************************** */
//RI NEED CORRECTION
async function getCategories(ruuid) {
  return await PrivateApiCall.get(`/admin/productCategory/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addCategory(ruuid, data) {
  return await PrivateApiCall.post(`/admin/productCategory/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editCategory(ruuid, uuid, data) {
  return await PrivateApiCall.put(
    `/admin/productCategory/${ruuid}/${uuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteCategory(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/productCategory/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function bulkUpdateCategories(ruuid, data) {
  return await PrivateApiCall.put(`/admin/productCategory/${ruuid}/`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function importCategories(ruuid, data) {
  return await PrivateApiCall.post(`/admin/productCategory/import/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ***************************************************************************************** */
/** ************************************* GROUP  ******************************************** */
/** ***************************************************************************************** */
//RI NEED CORRECTION
async function getGroups(ruuid) {
  return await PrivateApiCall.get(`/admin/productGroup/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addGroup(ruuid, data) {
  return await PrivateApiCall.post(`/admin/productGroup/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editGroup(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/productGroup/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteGroup(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/productGroup/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function bulkEditGroups(ruuid, data) {
  return await PrivateApiCall.put(`/admin/productGroup/${ruuid}/`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************** TAXES ********************************************** */
/** ******************************************************************************************* */
async function getTaxes() {
  return await PrivateApiCall.get(`/admin/tax`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addTax(data) {
  return await PrivateApiCall.post(`/admin/tax`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editTax(uuid, data) {
  return await PrivateApiCall.put(`/admin/tax/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteTax(uuid) {
  return await PrivateApiCall.delete(`/admin/tax/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getBranchTaxes(ruuid) {
  return await PrivateApiCall.get(`/admin/branch-taxing/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function updateBranchTaxes(ruuid, data) {
  return await PrivateApiCall.put(`/admin/branch-taxing/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function applyTaxesToProducts(ruuid, data) {
  return await PrivateApiCall.put(`/admin/product/${ruuid}/apply-taxes`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getProductsWithTaxes(ruuid) {
  return await PrivateApiCall.get(`/admin/product/${ruuid}/taxes`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function applyTaxesToAllProducts(ruuid, data) {
  return await PrivateApiCall.put(
    `/admin/product/${ruuid}/apply-taxes-to-all`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getModifiersWithTaxes(ruuid) {
  return await PrivateApiCall.get(`/admin/modifier/${ruuid}/taxes`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function applyTaxesToModifiers(ruuid, data) {
  return await PrivateApiCall.put(`/admin/modifier/${ruuid}/apply-taxes`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** Ingredients ********************************************** */
/** ******************************************************************************************* */
async function getIngredients() {
  return await PrivateApiCall.get(`/admin/ingredient`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addIngredient(data) {
  return await PrivateApiCall.post(`/admin/ingredient`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editIngredient(uuid, data) {
  return await PrivateApiCall.put(`/admin/ingredient/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteIngredient(uuid) {
  return await PrivateApiCall.delete(`/admin/ingredient/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function bulkUpdateIngredients(data) {
  return await PrivateApiCall.put(`/admin/ingredient/`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************TYPES AND ALLERGIES *********************************** */
/** ******************************************************************************************* */
async function getTypesAndAllergies(ruuid) {
  return await PrivateApiCall.get(`/admin/foodTypesAndAlergies/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addTypesAndAllergies(ruuid, data) {
  return await PrivateApiCall.post(`/admin/foodTypesAndAlergies/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editTypesAndAllergies(ruuid, uuid, data) {
  return await PrivateApiCall.put(
    `/admin/foodTypesAndAlergies/${ruuid}/${uuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteTypesAndAllergies(ruuid, uuid) {
  return await PrivateApiCall.delete(
    `/admin/foodTypesAndAlergies/${ruuid}/${uuid}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function bulkUpdateTypes(ruuid, data) {
  return await PrivateApiCall.put(`/admin/foodTypesAndAlergies/${ruuid}/`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************** PRODUCTS ******************************************* */
/** ******************************************************************************************* */
async function getProducts(ruuid) {
  return await PrivateApiCall.get(`/admin/product/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getProduct(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/product/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addProduct(ruuid, data) {
  return await PrivateApiCall.post(`/admin/product/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editProduct(data, ruuid, uuid) {
  return await PrivateApiCall.put(`/admin/product/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteProduct(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/product/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteProductImage(ruuid, uuid) {
  return await PrivateApiCall.put(
    `/admin/product/${ruuid}/${uuid}/delete-image`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editProductImage(ruuid, uuid, data) {
  return await PrivateApiCall.post(
    `/admin/upload/${ruuid}/product-image/${uuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editBulkProducts(ruuid, data) {
  return await PrivateApiCall.put(`/admin/product/${ruuid}/bulk-update`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** MODIFIERS ********************************************** */
/** ******************************************************************************************* */
async function getModifiers(ruuid) {
  return await PrivateApiCall.get(`/admin/modifier/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addModifier(ruuid, data) {
  return await PrivateApiCall.post(`/admin/modifier/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editModifier(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/modifier/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteModifier(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/modifier/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function bulkUpdateModifiers(ruuid, data) {
  return await PrivateApiCall.put(`/admin/modifier/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** ************************************** MODIFIERSGROUPS ********************************************** */
/** ******************************************************************************************* */
async function getModifiersGroups(ruuid) {
  return await PrivateApiCall.get(`/admin/modifierGroups/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getModifierGroup(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/modifierGroups/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getModifierGroupWithModifiers(ruuid, uuid) {
  return await PrivateApiCall.get(
    `/admin/modifierGroups/${ruuid}/${uuid}/modifiers`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getModifiersGroupsWithModifiers(ruuid) {
  return await PrivateApiCall.get(`/admin/modifierGroups/${ruuid}/modifiers`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addModifierGroup(ruuid, data) {
  return await PrivateApiCall.post(`/admin/modifierGroups/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editModifierGroup(ruuid, uuid, data) {
  return await PrivateApiCall.put(
    `/admin/modifierGroups/${ruuid}/${uuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteModifierGroup(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/modifierGroups/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function bulkUpdateModifierGroups(ruuid, data) {
  return await PrivateApiCall.put(`/admin/modifierGroups/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** Roles ********************************************** */
/** ******************************************************************************************* */
async function getRoles() {
  return await PrivateApiCall.get(`/admin/role`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addRole(data) {
  return await PrivateApiCall.post(`/admin/role`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editRole(uuid, data) {
  return await PrivateApiCall.put(`/admin/role/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteRole(uuid) {
  return await PrivateApiCall.delete(`/admin/role/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** Users ********************************************** */
/** ******************************************************************************************* */
async function getUsers() {
  return await PrivateApiCall.get(`/admin/users/adminUsers`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addUser(data) {
  return await PrivateApiCall.post(`/admin/users/adminUsers`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editUser(uuid, data) {
  return await PrivateApiCall.put(`/admin/users/edit/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteUser(uuid) {
  return await PrivateApiCall.delete(`/admin/users/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editUserSettings(data) {
  return await PrivateApiCall.put(`/admin/users/settings`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function changeAccountActivity(uuid, data) {
  return await PrivateApiCall.post(`/admin/users/${uuid}/status`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function changeAccountVerification(uuid, data) {
  return await PrivateApiCall.post(`/admin/users/${uuid}/verification`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function getAppUsers() {
  return await PrivateApiCall.get(`/admin/app-users/`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getAppUser(uuid) {
  return await PrivateApiCall.get(`/admin/app-users/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addAppUser(data) {
  return await PrivateApiCall.post(`/admin/app-users/add`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editAppUser(uuid, data) {
  return await PrivateApiCall.put(`/admin/app-users/${uuid}/edit`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteAppUser(uuid) {
  return await PrivateApiCall.put(`/admin/app-users/${uuid}/delete`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getRestaurantsWithBranches() {
  return await PrivateApiCall.get(`/admin/restaurant/branches`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ********************************** APP USERS ADDRESSES ************************************ */
/** ******************************************************************************************* */

async function getAppUserAddresses(uuid) {
  return await PrivateApiCall.get(`/admin/app-users-address/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addAppUserAddress(uuid, data) {
  return await PrivateApiCall.post(`/admin/app-users-address/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editAppUserAddress(uuid, data) {
  return await PrivateApiCall.put(`/admin/app-users-address/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteAppUserAddress(uuid) {
  return await PrivateApiCall.delete(`/admin/app-users-address/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** *********************************** APP USERS GROUPS ************************************** */
/** ******************************************************************************************* */
async function getAppUsersGroups() {
  return await PrivateApiCall.get(`/admin/app-users-group`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addAppUsersGroup(data) {
  return await PrivateApiCall.post(`/admin/app-users-group/`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function editAppUsersGroup(uuid, data) {
  return await PrivateApiCall.put(`/admin/app-users-group/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function deleteAppUsersGroup(uuid) {
  return await PrivateApiCall.delete(`/admin/app-users-group/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** *************************************** ALLERGENS ***************************************** */
/** ******************************************************************************************* */
async function getAllergens() {
  return await PrivateApiCall.get(`/admin/allergen`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getAllergen(uuid) {
  return await PrivateApiCall.get(`/admin/allergen/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addAllergen(data) {
  return await PrivateApiCall.post(`/admin/allergen`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editAllergen(uuid, data) {
  return await PrivateApiCall.put(`/admin/allergen/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteAllergen(uuid) {
  return await PrivateApiCall.delete(`/admin/allergen/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ******************************************* MENU ****************************************** */
/** ******************************************************************************************* */
async function getMenu(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/menu/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getMenus(ruuid) {
  return await PrivateApiCall.get(`/admin/menu/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addMenu(ruuid, data) {
  return await PrivateApiCall.post(`/admin/menu/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editMenu(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/menu/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteMenu(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/menu/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editMenuName(ruuid, uuid, data) {
  return await PrivateApiCall.put(
    `/admin/menu/${ruuid}/${uuid}/update-name`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** GLOBAL CONFIG ************************************** */
/** ******************************************************************************************* */

async function getGlobalConfig() {
  return await PublicApiCall.get("/globalconfig")
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function uploadImage(data, doNotCompress) {
  if (!doNotCompress)
    return new Promise((resolve, reject) => {
      new Compressor(data, {
        quality: 0.2,
        success(result) {
          // const compressedFile = Object.assign(result, {
          //   path: data.name,
          //   preview: URL.createObjectURL(result)
          // });
          const formData = new FormData();
          formData.append('image', result);
          // Now upload the compressed file
          PrivateApiCall.post("/admin/upload/image", formData)
            .then(response => resolve(response))
            .catch(error => reject(error));
        },
        error(err) {
          console.error(err.message);
          reject(err);
        },
      });
    });
  else {
    const formData = new FormData();
    formData.append('image', data);
    return await PrivateApiCall.post("/admin/upload/image", formData)
      .then(response => response)
      .catch(error => errorCatch(error));
  }
}


async function uploadQr(data) {
  return await PrivateApiCall.post("/admin/upload/qr", data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************* TRANSLATIONS **************************************** */
/** ******************************************************************************************* */
async function getTranslations() {
  return await PublicApiCall.get("/language?platform=resto-admin")
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ************************************** DELIVERY ZONE ************************************** */
/** ******************************************************************************************* */
async function getDeliveryZones(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/delivery-zone/${ruuid}/${bruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getDeliveryZone(ruuid, bruuid, uuid) {
  return await PrivateApiCall.get(
    `/admin/delivery-zone/${ruuid}/${bruuid}/${uuid}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addDeliveryZone(ruuid, bruuid, data) {
  return await PrivateApiCall.post(
    `/admin/delivery-zone/${ruuid}/${bruuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editDeliveryZone(ruuid, bruuid, uuid, data) {
  return await PrivateApiCall.put(
    `/admin/delivery-zone/${ruuid}/${bruuid}/${uuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteDeliveryZone(ruuid, bruuid, uuid) {
  return await PrivateApiCall.delete(
    `/admin/delivery-zone/${ruuid}/${bruuid}/${uuid}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getOnlineDeliverySettings(ruuid, bruuid) {
  return await PrivateApiCall.get(
    `/admin/online-delivery-setting/${ruuid}/${bruuid}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addEditDeliverySettings(ruuid, bruuid, data) {
  return await PrivateApiCall.post(
    `/admin/online-delivery-setting/${ruuid}/${bruuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ***************************************** EVENTS ***************************************** */
/** ******************************************************************************************* */
async function getEvents(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/events/${ruuid}/${bruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getEvent(ruuid, bruuid, uuid) {
  return await PrivateApiCall.get(`/admin/events/${ruuid}/${bruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addEvent(ruuid, bruuid, data) {
  return await PrivateApiCall.post(`/admin/events/${ruuid}/${bruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editEvent(ruuid, bruuid, uuid, data) {
  return await PrivateApiCall.put(
    `/admin/events/${ruuid}/${bruuid}/${uuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteEvent(ruuid, bruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/events/${ruuid}/${bruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** ****************************************** MEDIA ****************************************** */
/** ******************************************************************************************* */
async function getAllMedia(ruuid, page, limit) {
  return await PrivateApiCall.get(
    page && limit
      ? `/admin/media/${ruuid}?page=${page}&limit=${limit}`
      : `/admin/media/${ruuid}`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getAllMediaInteractions(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/media/${ruuid}/${uuid}/get-all`);
}
async function addMedia(ruuid, data) {
  return await PrivateApiCall.post(`/admin/media/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editMedia(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/media/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteMedia(ruuid, uuid) {
  return await PrivateApiCall.put(`/admin/media/${ruuid}/${uuid}/delete-media`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function hideMedia(ruuid, uuid) {
  return await PrivateApiCall.put(`/admin/media/${ruuid}/${uuid}/hide-media`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function showMedia(ruuid, uuid) {
  return await PrivateApiCall.put(`/admin/media/${ruuid}/${uuid}/show-media`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function sortMedia(ruuid, data) {
  return await PrivateApiCall.put(`/admin/media/${ruuid}/sort`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function updateMedia(ruuid, uuid, data) {
  return await PrivateApiCall.post(`/admin/upload/${ruuid}/media/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** **************************************** GALLERY ****************************************** */
/** ******************************************************************************************* */
async function getGallery(ruuid) {
  return await PrivateApiCall.get(`/admin/gallery/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function uploadGallery(ruuid, catuuid, data) {
  return await PrivateApiCall.post(
    `/admin/gallery/${ruuid}/${catuuid}/upload`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function updateLayout(ruuid, data) {
  return await PrivateApiCall.put(`/admin/gallery/${ruuid}/`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteGallery(ruuid, data) {
  return await PrivateApiCall.post(
    `/admin/gallery/${ruuid}/delete-photos`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getGalleryCategories(ruuid) {
  return await PrivateApiCall.get(`/admin/gallery/${ruuid}/gallery-categories`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getGalleryCategory(ruuid, catuuid) {
  return await PrivateApiCall.get(
    `/admin/gallery/${ruuid}/${catuuid}/gallery-categories`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addGalleryCategory(ruuid, data) {
  return await PrivateApiCall.post(
    `/admin/gallery/${ruuid}/gallery-categories`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editGalleryCategory(ruuid, catuuid, data) {
  return await PrivateApiCall.put(
    `/admin/gallery/${ruuid}/${catuuid}/gallery-categories`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteGalleryCategory(ruuid, catuuid) {
  return await PrivateApiCall.delete(
    `/admin/gallery/${ruuid}/${catuuid}/gallery-categories`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** **************************************** FEEDBACK ***************************************** */
/** ******************************************************************************************* */
async function getFeedbackTemplate(ruuid) {
  return await PrivateApiCall.get(`/admin/feedback-template/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editFeedbackTemplate(ruuid, data) {
  return await PrivateApiCall.put(`/admin/feedback-template/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editFeedbackTemplateMessage(ruuid, data) {
  return await PrivateApiCall.put(
    `/admin/feedback-templateMessage/${ruuid}`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getFeedback(ruuid) {
  return await PrivateApiCall.get(`/admin/feedback/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addFeedback(ruuid, data) {
  return await PrivateApiCall.post(`/admin/feedback/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editFeedbackComplaint(ruuid, fuuid, data) {
  return await PrivateApiCall.put(`/admin/feedback/${ruuid}/${fuuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

/** ******************************************************************************************* */
/** **************************************** MODULES ***************************************** */
/** ******************************************************************************************* */
async function getModules() {
  return await PrivateApiCall.get(`/organisation/modules`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** **************************************** PLATFORMS ***************************************** */
/** ******************************************************************************************* */
async function getPlatforms(ruuid) {
  return await PrivateApiCall.get(`/admin/platform/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addPlatform(ruuid, data) {
  return await PrivateApiCall.post(`/admin/platform/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editPlatform(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/platform/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deletePlatform(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/platform/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ****************************************************************************************** */
/** ************************************** PROMOTIONS **************************************** */
/** ****************************************************************************************** */
async function getAllPromotions(ruuid) {
  return await PrivateApiCall.get(`/admin/promotion/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getPromotion(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/promotion/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addPromotion(ruuid, data) {
  return await PrivateApiCall.post(`/admin/promotion/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editPromotion(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/promotion/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deletePromotion(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/promotion/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ****************************************************************************************** */
/** ************************************** BRANCH-FINANCE **************************************** */
/** ****************************************************************************************** */
async function getBranchesFinance(ruuid) {
  return await PrivateApiCall.get(`/admin/product/${ruuid}/branch-finances`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function updateBranchesFinanceAvailability(ruuid, data) {
  return await PrivateApiCall.put(
    `/admin/product/${ruuid}/branch-finance-availability`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function updateBranchesFinancePrice(ruuid, data) {
  return await PrivateApiCall.put(
    `/admin/product/${ruuid}/branch-finance-price`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getBranchesFinanceProduct(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/product/${ruuid}/${uuid}/branch-finances`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ****************************************************************************************** */
/** ************************************** LOYALTY ******************************************* */
/** ****************************************************************************************** */
async function getLoyalty(ruuid) {
  return await PrivateApiCall.get(`/admin/loyality/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addEditLoyalty(ruuid, data) {
  return await PrivateApiCall.put(`/admin/loyality/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addEditLoyaltyDescription(ruuid, data) {
  return await PrivateApiCall.put(`/admin/loyality/${ruuid}/description`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getLoyaltyDescription(ruuid) {
  return await PrivateApiCall.get(`/admin/loyality/${ruuid}/description`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getLoyaltyUsers(ruuid) {
  return await PrivateApiCall.get(`/admin/loyality-user/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addLoyaltyConfig(ruuid, data) {
  return await PrivateApiCall.put(`/admin/general-config/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getLoyaltyConfig(ruuid) {
  return await PrivateApiCall.get(`/admin/general-config/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getLoyaltyMenu(ruuid) {
  return await PrivateApiCall.get(`/admin/loyality-rewards/${ruuid}/menu`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addEditLoyaltyMenu(ruuid, data) {
  return await PrivateApiCall.put(`/admin/loyality-rewards/${ruuid}/menu`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getCashback(ruuid) {
  return await PrivateApiCall.get(`/admin/loyality-rewards/${ruuid}/CASHBACK`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getGifts(ruuid) {
  return await PrivateApiCall.get(`/admin/loyality-rewards/${ruuid}/gifts`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getGift(ruuid, uuid) {
  return await PrivateApiCall.get(
    `/admin/loyality-rewards/${ruuid}/${uuid}/gifts`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addEditCashback(ruuid, data) {
  return await PrivateApiCall.put(
    `/admin/loyality-rewards/${ruuid}/cashback`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editGift(ruuid, uuid, data) {
  return await PrivateApiCall.put(
    `/admin/loyality-rewards/${ruuid}/${uuid}/gifts`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addGift(ruuid, data) {
  return await PrivateApiCall.post(
    `/admin/loyality-rewards/${ruuid}/gifts`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteGift(ruuid, uuid) {
  return await PrivateApiCall.delete(
    `/admin/loyality-rewards/${ruuid}/${uuid}/gifts`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getRewardsBasedOnLevel(ruuid) {
  return await PrivateApiCall.get(
    `/admin/loyality-rewards/${ruuid}/reward-based-onlevel`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getRewardBasedOnLevel(ruuid, uuid) {
  return await PrivateApiCall.get(
    `/admin/loyality-rewards/${ruuid}/${uuid}/reward-based-onlevel`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteRewardBasedOnLevel(ruuid, uuid) {
  return await PrivateApiCall.delete(
    `/admin/loyality-rewards/${ruuid}/${uuid}/reward-based-onlevel`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editRewardBasedOnLevel(ruuid, uuid, data) {
  return await PrivateApiCall.put(
    `/admin/loyality-rewards/${ruuid}/${uuid}/reward-based-onlevel`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addRewardBasedOnLevel(ruuid, data) {
  return await PrivateApiCall.post(
    `/admin/loyality-rewards/${ruuid}/reward-based-onlevel`,
    data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editLoyaltyPoints(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/loyality-user/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getLoyaltyUserEntries(ruuid, uuid) {
  return await PrivateApiCall.get(ruuid ? `/admin/loyality-user/entries/${ruuid}/${uuid}` : `/admin/loyality-user/entries/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch((error)))
}
/** ****************************************************************************************** */
/** ************************************** DISCOUNT ******************************************* */
/** ****************************************************************************************** */
async function getAllDiscount(ruuid) {
  return await PrivateApiCall.get(`/admin/discount/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function getDiscount(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/discount/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function addDiscount(ruuid, data) {
  return await PrivateApiCall.post(`/admin/discount/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function editDiscount(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/discount/${ruuid}/${uuid}/edit`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function deleteDiscount(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/discount/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
async function sortDiscount(ruuid, data) {
  return await PrivateApiCall.put(`/admin/discount/${ruuid}/sort`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function editDiscountSettings(ruuid, data) {
  return await PrivateApiCall.put(`/admin/discount/${ruuid}/settings`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getDiscountSettings(ruuid) {
  return await PrivateApiCall.get(`/admin/discount/${ruuid}/settings`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ***************************************** ORDERS ****************************************** */
/** ******************************************************************************************* */

async function getOrders(ruuid, buuid, page, limit, filterUrl, cancelToken) {
  return await PrivateApiCall.get(`/admin/order/paginated/${ruuid}/${buuid}?page=${page}&limit=${limit}${filterUrl ? filterUrl : ""}`, {
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
//   return await PrivateApiCall.get(`/admin/order/paginated/${ruuid}/${buuid}?page=${page}&limit=${limit}${filter}`)
//     .then((response) => response)
//     .catch((error) => errorCatch(error));
// }

async function getOrder(ruuid, buuid, ouuid) {
  return await PrivateApiCall.get(`/admin/order/${ruuid}/${buuid}/${ouuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function cancelOrder(ruuid, buuid, ouuid, wasOrderAccepted = false, data) {
  return await PrivateApiCall.put(
    `/admin/order/${ruuid}/${buuid}/${ouuid}/${wasOrderAccepted ? "cancel-accepted-order" : "cancel-order"}`, data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function acceptOrder(ruuid, buuid, ouuid) {
  return await PrivateApiCall.put(
    `/admin/order/${ruuid}/${buuid}/${ouuid}/accept-order`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function rejectOrder(ruuid, buuid, ouuid, wasOrderAccepted = false, data) {
  return await PrivateApiCall.put(
    `/admin/order/${ruuid}/${buuid}/${ouuid}/${wasOrderAccepted ? "reject-accepted-order" : "reject-order"}`, data
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function completeOrder(ruuid, buuid, ouuid) {
  return await PrivateApiCall.put(
    `/admin/order/${ruuid}/${buuid}/${ouuid}/complete-order`
  )
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function dispatchOrder(ruuid, bruuid, data) {
  return await PrivateApiCall.put(`/admin/order/${ruuid}/${bruuid}/dispatch-order`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function updateDispatchSettings(ruuid, data) {
  return await PrivateApiCall.put(`/admin/general-config/dispatch-setup/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getDrivers(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/order/get-drivers/${ruuid}/${bruuid}/`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function undispatchAllOrders(ruuid, bruuid, duuid) {
  return await PrivateApiCall.put(`/admin/order/${ruuid}/${bruuid}/undispatch-orders/${duuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function undispatchOrder(ruuid, bruuid, duuid, ouuid) {
  return await PrivateApiCall.put(`/admin/order/${ruuid}/${bruuid}/undispatch-order/${duuid}/${ouuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function setBusy(ruuid, bruuid, data) {
  return await PrivateApiCall.put(`/admin/order/${ruuid}/${bruuid}/set-busy`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getUnclosedDays(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/report/${ruuid}/${bruuid}/unclosed-dates`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function closeDay(ruuid, bruuid, closingDay) {
  return await PrivateApiCall.post(`/admin/report/close-day/${ruuid}/${bruuid}`, closingDay)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function setClosed(ruuid, bruuid, data) {
  return await PrivateApiCall.put(`/admin/order/${ruuid}/${bruuid}/set-close`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getReasons() {
  return await PrivateApiCall.get(`/admin/reasons`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function addReason(data) {
  return await PrivateApiCall.post(`/admin/reasons`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editReason(uuid, data) {
  return await PrivateApiCall.put(`/admin/reasons/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteReason(uuid) {
  return await PrivateApiCall.delete(`/admin/reasons/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************** ORDERS HISTORY ************************************* */
/** ******************************************************************************************* */
// async function getOrdersHistory(ruuid, buuid, page, limit, filterUrl) {
//   return await PrivateApiCall.get(`/admin/order/history/${ruuid}/${buuid}?page=${1}&limit=${limit}${filterUrl ? filterUrl : ""}`)
//     .then((response) => response)
//     .catch((error) => errorCatch(error));
// }

async function getOrdersHistory(ruuid, buuid, page, limit, filterUrl, cancelToken) {
  // console.log(`/admin/order/history/${ruuid}/${buuid}?page=${page}${filterUrl ? filterUrl : ""}`)
  return await PrivateApiCall.get(`/admin/order/history/${ruuid}/${buuid}?page=${page}&limit=${limit}${filterUrl ? filterUrl : ""}`, {
    cancelToken: cancelToken,
  })
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** *****************************************  FLOOR SETUP  ****************************************** */
/** ******************************************************************************************* */

async function getFloors(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/floor/${ruuid}/${bruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getFloor(ruuid, bruuid, fuuid) {
  return await PrivateApiCall.get(`/admin/floor/${ruuid}/${bruuid}/${fuuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getTables(fuuid) {
  return await PrivateApiCall.get(`/admin/table/${fuuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editOrAddFloorsAndTables(ruuid, bruuid, data) {
  return await PrivateApiCall.put(`/admin/floor/${ruuid}/${bruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteTheFloor(ruuid, bruuid, fuuid) {
  return await PrivateApiCall.delete(`/admin/floor/${ruuid}/${bruuid}/${fuuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteTable(fuuid, tuuid) {
  return await PrivateApiCall.delete(`/admin/table/${fuuid}/${tuuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** *****************************************  QR  ****************************************** */
/** ******************************************************************************************* */

async function addQR(ruuid, bruuid, data) {
  return await PrivateApiCall.post(`/admin/qr/qr-setup/${ruuid}/${bruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function addQrTables(ruuid, bruuid, data) {
  return await PrivateApiCall.post(`/admin/qr/qr-tables/${ruuid}/${bruuid}/`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function addQrButton(ruuid, bruuid, data) {
  return await PrivateApiCall.post(`/admin/qr/qr-button/${ruuid}/${bruuid}/`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editQrButton(ruuid, bruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/qr/qr-button/${ruuid}/${bruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteQrButton(ruuid, bruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/qr/qr-button/${ruuid}/${bruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteQrTable(ruuid, bruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/qr/qr-tables/${ruuid}/${bruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getQrButton(ruuid, bruuid, uuid) {
  return await PrivateApiCall.get(`/admin/qr/qr-button/${ruuid}/${bruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getQrButtons(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/qr/qr-button/${ruuid}/${bruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getQr(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/qr/qr-setup/${ruuid}/${bruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function validateURL(ruuid, bruuid, data) {
  return await PrivateApiCall.post(`/admin/qr/url-validation/${ruuid}/${bruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function getQrTables(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/qr/qr-tables/${ruuid}/${bruuid}/`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function getTableOrderingSettings(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/table-ordering-setting/${ruuid}/${bruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function addEditTableOrderingSettings(ruuid, bruuid, data) {
  return await PrivateApiCall.post(`/admin/table-ordering-setting/${ruuid}/${bruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function getQrOnlineDelivery(ruuid) {
  return await PrivateApiCall.get(`/admin/qr/online-ordering/${ruuid}/`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function addOnlineDeliveryQr(ruuid, data) {
  return await PrivateApiCall.post(`/admin/qr/online-ordering/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** *************************************  URL LINK  ****************************************** */
/** ******************************************************************************************* */
async function getAffiliateLink(ruuid) {
  return await PrivateApiCall.get(`/admin/affiliate-link/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function addAffiliateLink(ruuid, data) {
  return await PrivateApiCall.post(`/admin/affiliate-link/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function editAffiliateLink(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/affiliate-link/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function deleteAffiliateLink(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/affiliate-link/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}
/** ******************************************************************************************* */
/** *************************************  DESIGN SETUP  ************************************** */
/** ******************************************************************************************* */

async function getDesignSetupGlobalSettings() {
  return await PrivateApiCall.get(`/admin/general-config/design-setup`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editDesignSetupGlobalSettings(data) {
  return await PrivateApiCall.put(`/admin/general-config/design-setup`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getDesignSetup(ruuid) {
  return await PrivateApiCall.get(`/admin/design-setup/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editDesignSetup(ruuid, data) {
  return await PrivateApiCall.post(`/admin/design-setup/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** **************************************  LOGIN SETUP  ************************************** */
/** ******************************************************************************************* */

async function getLoginDesignSetup() {
  return await PrivateApiCall.get(`/admin/loginDesignSetup`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editLoginDesignSetup(data) {
  return await PrivateApiCall.put(`/admin/loginDesignSetup`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** **********************************  SPLASH SCREEN SETUP  ********************************** */
/** ******************************************************************************************* */

async function getSplashScreenDesignSetup() {
  return await PrivateApiCall.get(`/admin/splashScreen`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editSplashScreenDesignSetup(data) {
  return await PrivateApiCall.put(`/admin/splashScreen`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** *********************************  PRODUCTS AVAILABILITY  ********************************* */
/** ******************************************************************************************* */

async function getProductsAvailability(ruuid, bruuid) {
  return await PrivateApiCall.get(`/admin/product-availability/${ruuid}/${bruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteProductAvailability(ruuid, bruuid, pruuid) {
  return await PrivateApiCall.delete(`/admin/product-availability/${ruuid}/${bruuid}/${pruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function updateProductsAvailability(ruuid, bruuid, data) {
  return await PrivateApiCall.put(`/admin/product-availability/${ruuid}/${bruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** *********************************  AUTOMATED-MESSAGES  ********************************* */
/** ******************************************************************************************* */

async function getAutomatedMessages(ruuid) {
  return await PrivateApiCall.get(`/admin/automatedMessage/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getAutomatedMessage(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/automatedMessage/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function addAutomatedMessage(ruuid, data) {
  return await PrivateApiCall.post(`/admin/automatedMessage/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editAutomatedMessage(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/automatedMessage/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteAutomatedMessage(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/automatedMessage/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** *********************************  DIRECT-MESSAGES  ********************************* */
/** ******************************************************************************************* */

async function getDirectMessages(ruuid) {
  return await PrivateApiCall.get(`/admin/directMessage/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getDirectMessage(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/directMessage/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function addDirectMessage(ruuid, data) {
  return await PrivateApiCall.post(`/admin/directMessage/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editDirectMessage(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/directMessage/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteDirectMessage(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/directMessage/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getDirectMessagesWithCustomers(ruuid) {
  return await PrivateApiCall.get(`/admin/directMessage/all/customers/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** **************************************  MESSAGES  ***************************************** */
/** ******************************************************************************************* */

async function getMessagesRestaurantsAndBranches() {
  return await PrivateApiCall.get(`/admin/restaurant/filtered-branches`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteChat(ruuid, bruuid, chatId) {
  return await PrivateApiCallMessaging.delete(`/${ruuid}/${bruuid}/${chatId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function getUserChats(ruuid, bruuid) {
  return await PrivateApiCallMessaging.get(`/branch/${ruuid}/${bruuid}/all-chats`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function addUpdateChat(ruuid, bruuid, uuid, data) {
  return await PrivateApiCallMessaging.put(`/${ruuid}/${bruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function getChatMessages(ruuid, bruuid, chatId, pageLimit, page) {
  return await PrivateApiCallMessaging.get(pageLimit && page ? `/${ruuid}/${bruuid}/${chatId}?pageNumber=${page}&pageLimit=${pageLimit}` : `/${ruuid}/${bruuid}/${chatId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function updateMessage(chatId, messageId, data) {
  return await PrivateApiCallMessaging.put(`/chat/updateMessage/${chatId}/${messageId}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
async function deleteMessage(chatId, messageId) {
  return await PrivateApiCallMessaging.delete(`/chat/deleteMessage/${chatId}/${messageId}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getUserGlobalChats() {
  return await PrivateApiCallMessaging.get(`/global/`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************  NOTIFICATIONS  *************************************** */
/** ******************************************************************************************* */

async function registerDevice(token) {
  return await PrivateApiCall.post(`/admin/notifications`, { token: token })
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getNotifications() {
  return await PrivateApiCall.get(`/admin/notifications`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function viewNotification(uuid) {
  return await PrivateApiCall.put(`/admin/notifications/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************ DASHBOARD  *************************************** */
/** ******************************************************************************************* */

const reshapeUrl = (baseUrl, ruuid, filterString) => {
  let final = baseUrl
  if (ruuid !== 'all-restaurants' && ruuid) {
    final += `/${ruuid}/`
  }
  if (filterString)
    final += filterString
  return final
}

async function getTopSpenders(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/top-customers`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getAvgOrderPrice(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl('/admin/dashboard/avg-order-price', ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getLateOrders(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl('/admin/dashboard/late-orders', ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getTotalOrders(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl('/admin/dashboard/total-orders', ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getNewCustomers(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl('/admin/dashboard/get-customer-count', ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getCanceledOrders(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl('/admin/dashboard/canceled-orders', ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getTotalCustomers() {
  return await PrivateApiCall.get('/admin/dashboard/total-customers')
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getSalesByZone(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl('/admin/dashboard/sales-by-zone', ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getSalesByAffiliate() {
  return await PrivateApiCall.get(`/admin/dashboard/sales-by-affiliate`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getSignupsByAffiliate() {
  return await PrivateApiCall.get(`/admin/dashboard/sign-ups-by-affiliate`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}
//NEW APIS
async function getTotalDiscounts(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/total-discounts`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getTotalPromoDiscounts(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/total-promo-discounts`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getTopXItemsQty(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/top-x-items`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getTopXItemsAmt(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/top-x-items-amount`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getNotActiveCustomers(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/not-active-customers`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getFeedbacksAndComplaints(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-feedbacks`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getFeedbacksRating(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-feebacks-rating`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getTotalRevenuePerMonth(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-revenue-month`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getNewProduct(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-new-product`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getCustomerCountValue(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-customer-count-above-value`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getCustomerCountOrder(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-customer-count-above-count`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getQrCount(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-qr-count`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getNewProductsList(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-new-product-list`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getFeedbacksSummary(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-feedback-summary`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getBirthdayCount(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-birthday-count`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getDeliveryStatus(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-delivery-status`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getNotSoldItems(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/not-sold-items`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getSalesByRestaurant(filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-sales-by-restaurant`, undefined, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getSalesByBranch(filterString) {
  return await PrivateApiCall.get(`/admin/dashboard/get-sales-by-branch`, undefined, filterString)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getAffiliateClicks() {
  return await PrivateApiCall.get(`/admin/dashboard/scans-by-affiliate`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getOrdersStatus(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-today-orders`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getTopItemsBranch(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/top-x-items-items-by-branch`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getAvgOrders(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-avg-orders`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getSalesByCategory(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-sales-by-category`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getSalesByCustomer(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-sales-by-customer`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getAvgPrepTime(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-avg-prep-time`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getAvgDeliveryTime(ruuid, filterString) {
  return await PrivateApiCall.get(reshapeUrl(`/admin/dashboard/get-avg-delivery-time`, ruuid, filterString))
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ******************************************************************************************* */
/** ************************************ EMAIL SETUP  *************************************** */
/** ******************************************************************************************* */

async function getEmailServer() {
  return await PrivateApiCall.get('/admin/email-server')
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function addEmailServer(data) {
  return await PrivateApiCall.post('/admin/email-server', data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function testEmail(data) {
  return await PrivateApiCall.post(`/admin/email-server/test`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ************************************************************************************** */
/** ************************************ PAYMENTS  *************************************** */
/** ************************************************************************************** */

async function addDefaultPayment(ruuid) {
  return await PrivateApiCall.post(`admin/payment/${ruuid}/auto-generate`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getPayments(ruuid) {
  return await PrivateApiCall.get(`/admin/payment/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getPayment(ruuid, uuid) {
  return await PrivateApiCall.get(`/admin/payment/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editPayment(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/payment/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deletePayment(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/payment/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function addPayment(ruuid, data) {
  return await PrivateApiCall.post(`/admin/payment/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

/** ************************************************************************************** */
/** ********************************** CURRENCIES  *************************************** */
/** ************************************************************************************** */

async function getRates(ruuid) {
  return await PrivateApiCall.get(`admin/currency-rate/${ruuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function getRate(ruuid, uuid) {
  return await PrivateApiCall.get(`admin/currency-rate/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editRate(ruuid, uuid, data) {
  return await PrivateApiCall.put(`/admin/currency-rate/${ruuid}/${uuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function deleteRate(ruuid, uuid) {
  return await PrivateApiCall.delete(`/admin/currency-rate/${ruuid}/${uuid}`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function addRate(ruuid, data) {
  return await PrivateApiCall.post(`/admin/currency-rate/${ruuid}`, data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function generateRates(ruuid) {
  return await PrivateApiCall.post(`admin/currency-rate/${ruuid}/auto-generate`)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

export {
  //DIRECT MESSAGES
  getDirectMessagesWithCustomers,
  deleteDirectMessage,
  editDirectMessage,
  addDirectMessage,
  getDirectMessage,
  getDirectMessages,
  //EMAIL-SETUP
  getEmailServer,
  addEmailServer,
  testEmail,
  //CURRENCIES
  getRates,
  getRate,
  addRate,
  editRate,
  deleteRate,
  generateRates,
  //PAYMENTS
  addDefaultPayment,
  getPayments,
  getPayment,
  editPayment,
  deletePayment,
  addPayment,
  //DASHBOARD
  getTopSpenders,
  getAvgOrderPrice,
  getLateOrders,
  getTotalOrders,
  getNewCustomers,
  getCanceledOrders,
  getSalesByZone,
  getTotalCustomers,
  getSalesByAffiliate,
  getSignupsByAffiliate,
  getTotalDiscounts,
  getTotalPromoDiscounts,
  getTopXItemsQty,
  getTopXItemsAmt,
  getNotActiveCustomers,
  getFeedbacksAndComplaints,
  getFeedbacksRating,
  getTotalRevenuePerMonth,
  getNewProduct,
  getCustomerCountValue,
  getCustomerCountOrder,
  getQrCount,
  getNewProductsList,
  getFeedbacksSummary,
  getBirthdayCount,
  getDeliveryStatus,
  getNotSoldItems,
  getSalesByRestaurant,
  getSalesByBranch,
  getAffiliateClicks,
  getOrdersStatus,
  getTopItemsBranch,
  getAvgOrders,
  getSalesByCategory,
  getSalesByCustomer,
  getAvgDeliveryTime,
  getAvgPrepTime,
  // NOTIFICATIONS
  registerDevice,
  getNotifications,
  viewNotification,
  //MESSAGES
  deleteChat,
  getUserChats,
  addUpdateChat,
  getChatMessages,
  updateMessage,
  deleteMessage,
  getUserGlobalChats,
  getMessagesRestaurantsAndBranches,
  //AUTOMATED-MESSAGES
  getAutomatedMessages,
  getAutomatedMessage,
  addAutomatedMessage,
  editAutomatedMessage,
  deleteAutomatedMessage,
  //PRODUCTS-AVAILABILITY
  getProductsAvailability,
  deleteProductAvailability,
  updateProductsAvailability,
  //QR
  addQR,
  addQrTables,
  addQrButton,
  editQrButton,
  deleteQrButton,
  deleteQrTable,
  getQrButton,
  getQrButtons,
  getQr,
  validateURL,
  getQrTables,
  getTableOrderingSettings,
  addEditTableOrderingSettings,
  getQrOnlineDelivery,
  addOnlineDeliveryQr,
  // AUTH
  login,
  forgotPass,
  otp,
  verifyOTP,
  // ME INFO
  getMeProfile,
  editMeProfile,
  // Admin PROFILES
  getAdminProfiles,
  addAdminProfile,
  editAdminProfile,
  deleteAdminProfile,
  // RESTAURANT
  getRestaurants,
  addRestaurant,
  editRestaurant,
  deleteRestaurant,
  // BRANCHES
  getBranches,
  addBranch,
  editBranch,
  deleteBranch,
  getBranchesTableOrdering,
  getBranchesLoyalty,
  getBranchesQRMenu,
  getBranchesOnlineDelivery,
  // GLOBAL CONFIG
  getGlobalConfig,
  uploadImage,
  uploadQr,
  // CATEGORY
  getCategories,
  addCategory,
  editCategory,
  deleteCategory,
  bulkUpdateCategories,
  importCategories,
  // GROUPS
  getGroups,
  addGroup,
  editGroup,
  deleteGroup,
  bulkEditGroups,
  // PRODUCTS
  getProducts,
  getProduct,
  addProduct,
  editProduct,
  deleteProduct,
  deleteProductImage,
  editProductImage,
  editBulkProducts,
  // TAXES
  getTaxes,
  addTax,
  editTax,
  deleteTax,
  getBranchTaxes,
  updateBranchTaxes,
  applyTaxesToProducts,
  getProductsWithTaxes,
  applyTaxesToAllProducts,
  getModifiersWithTaxes,
  applyTaxesToModifiers,
  // Ingredients
  getIngredients,
  addIngredient,
  editIngredient,
  deleteIngredient,
  bulkUpdateIngredients,
  // TYPES AND ALLERGIES
  getTypesAndAllergies,
  addTypesAndAllergies,
  editTypesAndAllergies,
  deleteTypesAndAllergies,
  bulkUpdateTypes,
  // MODIFIERS
  getModifiers,
  addModifier,
  editModifier,
  deleteModifier,
  bulkUpdateModifiers,
  // MODIFIERSGROUPS
  getModifiersGroups,
  getModifierGroup,
  getModifierGroupWithModifiers,
  getModifiersGroupsWithModifiers,
  addModifierGroup,
  editModifierGroup,
  deleteModifierGroup,
  bulkUpdateModifierGroups,
  // MENU
  getMenu,
  getMenus,
  addMenu,
  editMenu,
  deleteMenu,
  editMenuName,
  // TRANSLATIONS
  getTranslations,
  //Roles
  getRoles,
  addRole,
  editRole,
  deleteRole,
  //Users
  getUsers,
  addUser,
  editUser,
  deleteUser,
  editUserSettings,
  changeAccountActivity,
  changeAccountVerification,
  getAppUsers,
  getAppUser,
  addAppUser,
  editAppUser,
  deleteAppUser,
  getRestaurantsWithBranches,
  //APP USERS ADDRESSES
  getAppUserAddresses,
  addAppUserAddress,
  editAppUserAddress,
  deleteAppUserAddress,
  //APP USERS GROUPS
  getAppUsersGroups,
  addAppUsersGroup,
  editAppUsersGroup,
  deleteAppUsersGroup,
  //ALLERGENS
  getAllergens,
  getAllergen,
  addAllergen,
  editAllergen,
  deleteAllergen,
  //DELIVERY-ZONE
  getDeliveryZones,
  getDeliveryZone,
  addDeliveryZone,
  editDeliveryZone,
  deleteDeliveryZone,
  getOnlineDeliverySettings,
  addEditDeliverySettings,
  //EVENTS
  getEvents,
  getEvent,
  addEvent,
  editEvent,
  deleteEvent,
  //MEDIA
  getAllMediaInteractions,
  getAllMedia,
  addMedia,
  editMedia,
  deleteMedia,
  hideMedia,
  showMedia,
  sortMedia,
  updateMedia,
  //GALLERY
  getGallery,
  uploadGallery,
  updateLayout,
  deleteGallery,
  getGalleryCategories,
  getGalleryCategory,
  addGalleryCategory,
  editGalleryCategory,
  deleteGalleryCategory,
  //FEEDBACK
  getFeedbackTemplate,
  editFeedbackTemplate,
  editFeedbackTemplateMessage,
  getFeedback,
  addFeedback,
  editFeedbackComplaint,
  //MODULES
  getModules,
  //PLATFORMS
  getPlatforms,
  addPlatform,
  editPlatform,
  deletePlatform,
  //PROMOTIONS
  getAllPromotions,
  getPromotion,
  addPromotion,
  editPromotion,
  deletePromotion,
  //BRANCHES-FINANCE
  getBranchesFinance,
  updateBranchesFinancePrice,
  updateBranchesFinanceAvailability,
  getBranchesFinanceProduct,
  //LOYALTY
  getLoyalty,
  addEditLoyalty,
  addEditLoyaltyDescription,
  getLoyaltyDescription,
  getLoyaltyUsers,
  addLoyaltyConfig,
  getLoyaltyConfig,
  getLoyaltyMenu,
  addEditLoyaltyMenu,
  getCashback,
  getGifts,
  getGift,
  addEditCashback,
  editGift,
  addGift,
  deleteGift,
  getRewardsBasedOnLevel,
  getRewardBasedOnLevel,
  addRewardBasedOnLevel,
  editRewardBasedOnLevel,
  deleteRewardBasedOnLevel,
  editLoyaltyPoints,
  getLoyaltyUserEntries,
  //DISCOUNT
  getAllDiscount,
  getDiscount,
  addDiscount,
  editDiscount,
  deleteDiscount,
  sortDiscount,
  editDiscountSettings,
  getDiscountSettings,
  //ORDERS
  getOrders,
  getOrder,
  cancelOrder,
  acceptOrder,
  rejectOrder,
  completeOrder,
  dispatchOrder,
  updateDispatchSettings,
  getDrivers,
  undispatchOrder,
  undispatchAllOrders,
  setBusy,
  closeDay,
  getUnclosedDays,
  setClosed,
  getReasons,
  addReason,
  editReason,
  deleteReason,
  //ORDERS HISTORY
  getOrdersHistory,
  //DESIGN SETUP
  getDesignSetupGlobalSettings,
  editDesignSetupGlobalSettings,
  getDesignSetup,
  editDesignSetup,
  //LOGIN DESIGN SETUP
  getLoginDesignSetup,
  editLoginDesignSetup,
  //SPLASH SCREEN DESIGN SETUP
  getSplashScreenDesignSetup,
  editSplashScreenDesignSetup,
  //FLOOR SETUP
  getFloors,
  getFloor,
  getTables,
  editOrAddFloorsAndTables,
  deleteTheFloor,
  deleteTable,
  getBranchesFloorSetup,
  //URL LINK
  getAffiliateLink,
  addAffiliateLink,
  editAffiliateLink, deleteAffiliateLink,
};
