import React, { lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  matchPath,
} from "react-router-dom";
import { styled, Box } from "@mui/material";
import layoutActions from "../../redux/actions/LayoutActions";
import loyaltyActions from '../../redux/actions/LoyaltyActions.js'
import deliveryActions from '../../redux/actions/DeliveryZoneActions.js'
import qrActions from '../../redux/actions/QRActions.js'

/* AUTH CHECK */
// import AdminRoute from '../../routers/AdminRoute';
import UserRoute from "../../routers/UserRoute";

// Components
import Loading from "../../components/loaders/Loading";

import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import TopNavigation from "../../components/TopNavigation/TopNavigation.js";
import ProductAttributesLayout from "./ProductAttributesLayout";
import LoyaltyLayout from "./loyaltyLayout";
import OffersLayout from "./OffersLayout";
import TaxesLayout from "./TaxesLayout";
import TableOrderingLayout from "./TableOrderingLayout";
import RolesLayout from "./RolesLayout";
import CustomersLayout from "./CustomersLayout";
import DeliveryLayout from "./DeliveryLayout";
import { GetScreenSize } from "../../utils/screenSize";
import StockLayout from "./StockLayout";
import MarketingLayout from "./MarketingLayout.js";
import LoyaltyConfig from "../../pages/loyalty/LoyaltyConfig.js";
import DeliverySettings from "../../pages/deliverySetup/components/DeliverySettings.js"
import QRConfig from "../../pages/tableOrdering/components/QRConfig.js";
import DesignSetupLayout from "./DesignSetupLayout.js";
import SettingsLayout from "./SettingsLayout.js";

/* PAGES */
const components = {
  dashboard: lazy(() => import("../../pages/dashboard/Dashboard")),
  userProfile: lazy(() => import("../../pages/admin/userProfile")),
  restaurantsAdd: lazy(() => import("../../pages/restaurant/AddRestaurant")),
  restaurantsEdit: lazy(() => import("../../pages/restaurant/EditRestaurant")),
  branches: lazy(() => import("../../pages/branch/Branches")),
  addBranch: lazy(() => import("../../pages/branch/AddBranch")),
  editBranch: lazy(() => import("../../pages/branch/EditBranch")),
  menuDisplay: lazy(() => import("../../pages/menuDisplay/MenuDisplay")),
  orders: lazy(() => import("../../pages/IncomingOrders/IncomingOrders")),
  order: lazy(() => import("../../pages/OrderDetails/OrderDetails")),
  ordersHistory: lazy(() => import("../../pages/ordersHistory/ordersHistory.js")),
  feedbackTemplate: lazy(() => import("../../pages/feedbacks/FeedbackSetup")),
  feedback: lazy(() => import("../../pages/feedbacks/FeedbackData")),
  gallery: lazy(() => import("../../pages/gallery/Gallery")),
  events: lazy(() => import("../../pages/events/Events")),
  mediaAds: lazy(() => import("../../pages/media/Media")),
  branchesPricesAndAvailability: lazy(() =>
    import("../../pages/menuDisplay/branchesPricesAndAvailability")
  ),
  multiMenu: lazy(() => import("../../pages/menuDisplay/multiMenu/MultiMenu")),
  messaging: lazy(() => import("../../pages/messages")),
  customers: lazy(() => import("../../pages/customers/Customers")),

  productsAvailability: lazy(() =>
    import("../../pages/IncomingOrders/ProductsAvailability")
  ),
  marketing: lazy(() => import("../../pages/marketing/MarketingDashboard")),
  dispatch: lazy(() => import("../../pages/IncomingOrders/Dispatch")),
  reservationSetup: lazy(() => import("../../pages/reservation/reservationSetup/floorSetup.js")),
  reservationManager: lazy(() => import("../../pages/reservation/reservationManager/ReservationManager.js")),
  paymentTypes: lazy(() => import("../../pages/finance/paymentTypes/paymentTypes.js")),
  currencies: lazy(() => import("../../pages/finance/currencies/currencies.js")),
  users: lazy(() => import("../../pages/users/Users.js")),
};

const userProfile = lazy(() => import("../../pages/admin/userProfile"));
// const Users = lazy(() => import("../../pages/users/Users"));
const Profileuser = lazy(() => import("../../pages/Profileuser/Profileuser"));

const Layout = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  flexDirection: 'column',
  justifyContent: 'flex-start'
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, screensize }) => ({
    cursor: "default",
    flexGrow: 1,
    padding: theme.spacing(1),
    // paddingTop: "50px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "auto",
    // WebkitOverflowScrolling: "touch",
    ...(open &&
      screensize.dynamicWidth > 666 && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: "200px",
    }),
  })
);
const Tabs = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, screensize }) => ({
    backgroundColor: theme.palette.background.paper,
    // position: "absolute",
    // top: 0,
    zIndex: 5,
    maxWidth: "100%",
    width: "100%",
    //marginTop: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open &&
      screensize.dynamicWidth > 666 && {
      transition: theme.transitions.create("padding", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      paddingLeft: "190px",
    }),
  })
);

const Reformat = (data) => {
  let final = [];
  data?.map((permission) => {
    if (!permission.isPopup || (permission.isPopup && permission.route)) {
      // if (permission.route) {
      if (permission.pageType === "layout") {
        let obj = { ...permission };
        obj.subLayouts = [];
        let tmp = permission.subPage?.filter(
          (perm) => perm.pageType === "layout"
        );
        if (tmp?.length > 0) obj.subLayouts = tmp;
        final.push(obj);
      } else {
        final.push(permission);
        if (permission.subPage?.length > 0)
          final.push(...Reformat(permission.subPage));
      }
    }
  });
  return final?.filter(
    (data) =>
      (data?.pageType !== null || data?.type !== "ver") && data?.isActive
  );
};
function DefaultLayout({
  sideBarState,
  headerHidden,
  updateHeaderState,
  pathCheck,
  loading,
  permissions,
  loyaltySettingsModalState,
  setSettingsModalState,
  setDeliverySettingsModalState,
  deliverySettingsModalState,
  tableOrderingSettingsModalState,
  setTableOrderingSettingsModalState
}) {
  const handleCloseLoyaltySettings = () => {
    setSettingsModalState(false)
  }
  const handleCloseDeliverySettings = () => {
    setDeliverySettingsModalState(false)
  }
  const handleCloseTableOrderingSettings = () => {
    setTableOrderingSettingsModalState(false)
  }
  const [access, setAccess] = useState([])
  useEffect(() => {
    setAccess(Reformat(permissions))
  }, [permissions])

  const pathname = window.location.pathname;

  useEffect(() => {
    updateHeaderState(false);
  }, [window.location.pathname]);
  const getHeader = (key, permission, reformat) => {
    switch (key) {
      case "taxes":
        return <TopNavigation permission={permission} reformat={reformat} />;
      case "loyalty":
        return (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100vw'
          }}>
            <TopNavigation permission={permission} reformat={reformat} />
            <LoyaltyConfig
              isOpen={loyaltySettingsModalState}
              onClose={handleCloseLoyaltySettings}
            />
          </Box>
        );
      case "customers":
        return <TopNavigation permission={permission} reformat={reformat} />;
      case "offers":
        return <TopNavigation permission={permission} reformat={reformat} />;
      case "tableOrderingSetup":
        return (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100vw'
          }}>
            <TopNavigation permission={permission} reformat={reformat} />
            <QRConfig
              isOpen={tableOrderingSettingsModalState}
              onClose={handleCloseTableOrderingSettings}
            />
          </Box>
        );
      case "onlineDeliverySetup":
        return (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100vw'
          }}>
            <TopNavigation permission={permission} reformat={reformat} />
            <DeliverySettings
              isOpen={deliverySettingsModalState}
              onClose={handleCloseDeliverySettings}
            />
          </Box>
        );
      case "productAttributes":
        return (
          <TopNavigation permission={permission} reformat={reformat} />
        );
      case "stockApp":
        return <TopNavigation permission={permission} reformat={reformat} />;
      case "rewards":
        return (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100vw'
          }}>
            <TopNavigation permission={permission} reformat={reformat} isParent />
            <TopNavigation
              permission={permission.subLayout}
              reformat={reformat}
            />
            <LoyaltyConfig
              isOpen={loyaltySettingsModalState}
              onClose={handleCloseLoyaltySettings}
            />
          </Box>
        );
      case "roles":
        return <TopNavigation permission={permission} reformat={reformat} />;
      // case 'autoMessages':
      //   return (
      //     <Box sx={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //       justifyContent: 'center',
      //       alignItems: 'center',
      //       maxWidth: '100vw'
      //     }}>            <TopNavigation permission={permission} reformat={reformat} isParent />
      //       <TopNavigation permission={permission.subLayout} reformat={reformat} />
      //     </Box>
      //   )
      // case 'directMessages':
      //   return (
      //     <Box sx={{
      //       display: 'flex',
      //       flexDirection: 'column',
      //       justifyContent: 'center',
      //       alignItems: 'center',
      //       maxWidth: '100vw'
      //     }}>
      //       <TopNavigation permission={permission} reformat={reformat} isParent />
      //       <TopNavigation permission={permission.subLayout} reformat={reformat} />
      //     </Box>

      //   )
      case 'marketing':
        return <TopNavigation permission={permission} reformat={reformat} />;
      case 'designSetup':
        return <TopNavigation permission={permission} reformat={reformat} />;
      case 'settings':
        return <TopNavigation permission={permission} reformat={reformat} />;
      default:
        return null;
    }
  };
  const tabsSwitch = () => {
    const matchedPermission = access.find(
      (data) => data.pageType === "layout" && data.route === pathCheck
    );
    let partialMatchedPermission;
    access
      .filter((data) => data.pageType === "layout")
      .map((perm) => {
        let obj = { ...perm };
        if (perm.subLayouts?.length > 0) {
          perm.subLayouts.map((sub) => {
            if (sub.route === pathCheck) {
              obj.subLayout = sub;
              partialMatchedPermission = obj;
            }
          });
        }
      });
    if (matchedPermission) {
      return getHeader(matchedPermission.key, matchedPermission, Reformat);
    } else if (partialMatchedPermission) {
      return getHeader(
        partialMatchedPermission.subLayout.key,
        partialMatchedPermission,
        Reformat
      );
    }

    // Return a default value or handle the case where there is no matching permission
    return null;
  };


  const getLayout = (key, props, permission, reformat) => {
    switch (key) {
      case 'settings':
        return (
          <SettingsLayout {...props} permission={permission} reformat={reformat} />
        )
      case "taxes":
        return (
          <TaxesLayout {...props} permission={permission} reformat={reformat} />
        );
      case "loyalty":
        return (
          <LoyaltyLayout
            {...props}
            permission={permission}
            reformat={reformat}
          />
        );
      case "customers":
        return (
          <CustomersLayout
            {...props}
            permission={permission}
            reformat={reformat}
          />
        );
      case "offers":
        return (
          <OffersLayout
            {...props}
            permission={permission}
            reformat={reformat}
          />
        );
      case "tableOrderingSetup":
        return (
          <TableOrderingLayout
            {...props}
            permission={permission}
            reformat={reformat}
          />
        );
      case "onlineDeliverySetup":
        return (
          <DeliveryLayout
            {...props}
            permission={permission}
            reformat={reformat}
          />
        );
      case "productAttributes":
        return (
          <ProductAttributesLayout
            {...props}
            permission={permission}
            reformat={reformat}
          />
        );
      case "stockApp":
        return (
          <StockLayout {...props} permission={permission} reformat={reformat} />
        );
      case "roles":
        return (
          <RolesLayout {...props} permission={permission} reformat={reformat} />
        );
      case 'marketing':
        return (
          <MarketingLayout {...props} permission={permission} reformat={reformat} />
        )
      case "designSetup":
        return (
          <DesignSetupLayout
            {...props}
            permission={permission}
            reformat={reformat}
          />
        );
      default:
        return null;
    }
  };

  const screenSize = GetScreenSize();
  return (
    <Layout>
      {loading && <Loading />}
      {!headerHidden && <Header />}
      <Box sx={{ marginTop: '50px' }}></Box>
      {matchPath(pathname, {
        path: pathCheck + "*",
        exact: true,
      }) && (
          <Tabs open={sideBarState} screensize={screenSize}>
            {tabsSwitch()}
          </Tabs>
        )}
      <SideBar />
      <Main open={sideBarState} screensize={screenSize}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          {access.map((permission, i) => {
            if (permission.pageType !== "layout")
              return (
                <UserRoute
                  key={`${permission.uuid}`}
                  exact
                  path={permission.route}
                  component={components[permission.key]}
                  permission={permission}
                />
              );
            else
              return (
                <Route
                  key={`${permission.uuid}`}
                  path={permission.route}
                  render={(props) =>
                    getLayout(permission.key, props, permission, Reformat)
                  }
                />
              );
          })}
          {/* <UserRoute exact path="/users" component={Users} /> */}
          <UserRoute exact path="/Profileuser" component={Profileuser} />
          <UserRoute exact path="/user-profile" component={userProfile} />
          {/* <Redirect from="*" to="/not-found" /> */}
        </Switch>
      </Main>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  settings: state.layout.settings,
  sideBarState: state.layout.settings?.layoutSettings?.sidebar?.open,
  headerHidden: state.layout.headerHidden,
  pathCheck: state.layout.pathCheck,
  loading: state.branch.loading || state.rest.loading,
  permissions: state.auth.permissions,
  loyaltySettingsModalState: state.loyalty.settingsModalState,
  deliverySettingsModalState: state.zone.deliverySettingsModalState,
  tableOrderingSettingsModalState: state.qr.QRSettingsModalState
});
const mapDispatchToProps = (dispatch) => ({
  updateHeaderState: (state) =>
    dispatch(layoutActions.updateHeaderState(state)),
  setSettingsModalState: (data) => dispatch(loyaltyActions.setSettingsModalState(data)),
  setDeliverySettingsModalState: (data) =>
    dispatch(deliveryActions.setDeliverySettingsModalState(data)),
  setTableOrderingSettingsModalState: (data) => dispatch(qrActions.setSettingsModalState(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DefaultLayout));
