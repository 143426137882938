import moment from 'moment'
import {
    getTopSpenders,
    getAvgOrderPrice,
    getLateOrders,
    getTotalOrders,
    getNewCustomers,
    getCanceledOrders,
    getSalesByZone,
    // getTotalCustomers,
    getSalesByAffiliate,
    getSignupsByAffiliate,
    getTotalDiscounts,
    getTotalPromoDiscounts,
    getTopXItemsQty,
    getTopXItemsAmt,
    getFeedbacksAndComplaints,
    getFeedbacksRating,
    getNotActiveCustomers,
    getTotalRevenuePerMonth,
    getNewProduct,
    getCustomerCountValue,
    getCustomerCountOrder,
    getQrCount,
    getNewProductsList,
    getFeedbacksSummary,
    getBirthdayCount,
    getDeliveryStatus,
    getNotSoldItems,
    getSalesByRestaurant,
    getSalesByBranch,
    getAffiliateClicks,
    getOrdersStatus,
    getTopItemsBranch,
    getAvgOrders,
    getSalesByCategory,
    getSalesByCustomer,
    getAvgPrepTime,
    getAvgDeliveryTime
} from '../../config/config'
import { handleError } from '../../utils/utils'

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_ERROR = 'GET_DASHBOARD_ERROR'

export const GET_DASHBOARD_TRANSACTION_REQUEST = 'GET_DASHBOARD_TRANSACTION_REQUEST'
export const GET_DASHBOARD_TRANSACTION_SUCCESS = 'GET_DASHBOARD_TRANSACTION_SUCCESS'
export const GET_DASHBOARD_TRANSACTION_ERROR = 'GET_DASHBOARD_TRANSACTION_ERROR'

export const GET_DASHBOARD_PRODUCTS_REQUEST = 'GET_DASHBOARD_PRODUCTS_REQUEST'
export const GET_DASHBOARD_PRODUCTS_SUCCESS = 'GET_DASHBOARD_PRODUCTS_SUCCESS'
export const GET_DASHBOARD_PRODUCTS_ERROR = 'GET_DASHBOARD_PRODUCTS_ERROR'

export const GET_DASHBOARD_CUSTOMERS_REQUEST = 'GET_DASHBOARD_CUSTOMERS_REQUEST'
export const GET_DASHBOARD_CUSTOMERS_SUCCESS = 'GET_DASHBOARD_CUSTOMERS_SUCCESS'
export const GET_DASHBOARD_CUSTOMERS_ERROR = 'GET_DASHBOARD_CUSTOMERS_ERROR'

export const GET_DASHBOARD_TOTAL_REVENUE_REQUEST = 'GET_DASHBOARD_TOTAL_REVENUE_REQUEST'
export const GET_DASHBOARD_TOTAL_REVENUE_SUCCESS = 'GET_DASHBOARD_TOTAL_REVENUE_SUCCESS'
export const GET_DASHBOARD_TOTAL_REVENUE_ERROR = 'GET_DASHBOARD_TOTAL_REVENUE_ERROR'

export const GET_DASHBOARD_ORDERS_VALUE_REQUEST = 'GET_DASHBOARD_ORDERS_VALUE_REQUEST'
export const GET_DASHBOARD_ORDERS_VALUE_SUCCESS = 'GET_DASHBOARD_ORDERS_VALUE_SUCCESS'
export const GET_DASHBOARD_ORDERS_VALUE_ERROR = 'GET_DASHBOARD_ORDERS_VALUE_ERROR'

export const GET_DASHBOARD_ORDERS_COUNT_REQUEST = 'GET_DASHBOARD_ORDERS_COUNT_REQUEST'
export const GET_DASHBOARD_ORDERS_COUNT_SUCCESS = 'GET_DASHBOARD_ORDERS_COUNT_SUCCESS'
export const GET_DASHBOARD_ORDERS_COUNT_ERROR = 'GET_DASHBOARD_ORDERS_COUNT_ERROR'

export const GET_DASHBOARD_NEW_PRODUCTS_REQUEST = 'GET_DASHBOARD_NEW_PRODUCTS_REQUEST'
export const GET_DASHBOARD_NEW_PRODUCTS_SUCCESS = 'GET_DASHBOARD_NEW_PRODUCTS_SUCCESS'
export const GET_DASHBOARD_NEW_PRODUCTS_ERROR = 'GET_DASHBOARD_NEW_PRODUCTS_ERROR'

export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST'
export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS'
export const GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR = 'GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR'

export const SET_DASHBOARD_REST = 'SET_DASHBOARD_REST'

export const CLEAR_DASHBOARD_LOADING = 'CLEAR_DASHBOARD_LOADING'

const possibleKeys = [
    //'buuid',
    // 'fromDate',
    // 'toDate',
    // 'key',
    'limit',
    'order',
    'years',
    'amount',
    'count',
]

const keys = {
    notSoldItems: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    deliveryStatus: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    topSpenders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    avgOrderPrice: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    lateOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    newCustomers: [
        'fromDate',
        'toDate',
        'key'
    ],
    canceledOrders: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    salesByZone: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    salesByAffiliate: [],
    signupsByAffiliate: [],
    totalDiscounts: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalPromoDiscounts: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    topXItems: [
        'fromDate',
        'toDate',
        'buuid',
        'limit',
        'key',
        'order'
    ],
    topXItemsAmt: [
        'fromDate',
        'toDate',
        'buuid',
        'limit',
        'key',
        'order'
    ],
    notActiveCustomers: [
        'fromDate',
        'toDate',
        'key',
        'buuid'
    ],
    feedbacksAndComplaints: [
        'fromDate',
        'toDate',
        'key',
        'limit',
        'buuid'
    ],
    feedbacksRating: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    totalRevenueMonth: [
        'key',
        'years',
        'buuid'
    ],
    newProducts: [
        'fromDate',
        'toDate'
    ],
    customerCountValue: [
        'fromDate',
        'toDate',
        'key',
        'amount',
        'buuid'
    ],
    customerCountOrder: [
        'fromDate',
        'toDate',
        'key',
        'count',
        'buuid'
    ],
    qrScans: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    newProductsList: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    feedbacksSummary: [
        'fromDate',
        'toDate',
        'limit',
        'key',
        'buuid'
    ],
    birthdayCount: [
        'fromDate',
        'toDate'
    ],
    salesByRestaurant: [
        'fromDate',
        'toDate'
    ],
    salesByBranch: [
        'fromDate',
        'toDate'
    ],
    ordersStatus: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    topItemsBranch: [
        'fromDate',
        'toDate',
        'limit',
        'buuid'
    ],
    avgOrders: [
        'fromDate',
        'toDate',
        'buuid'
    ],
    salesByCategory: [
        'fromDate',
        'toDate'
    ],
    salesByCustomer: [
        'fromDate',
        'toDate'
    ],
    avgPrepTime: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ],
    avgDelvTime: [
        'fromDate',
        'toDate',
        'buuid',
        'key'
    ]
}

const getFilterString = (
    queryKeys,
    selectedBranches,
    filter,
    activeSection,
    topItemsLimit,
    topItemsAmtLimit,
    feedbacksAndComplaintsLimit,
    feedbacksSummaryLimit,
    years,
    amount,
    count,
    thisYear,
    forcedKey,
    order,
    topItemsBranchLimit
) => {
    let targetQueries = keys[queryKeys];
    if (!targetQueries) return '';

    let filterString = '?';
    let queryParts = [];

    targetQueries.forEach((key) => {
        switch (key) {
            case 'fromDate':
                queryParts.push(
                    !thisYear
                        ? `fromDate=${filter.period.start.format('YYYY-MM-DD')}`
                        : `fromDate=${moment().startOf('year').format('YYYY-MM-DD')}`
                );
                break;

            case 'toDate':
                queryParts.push(
                    !thisYear
                        ? `toDate=${filter.period.end.format('YYYY-MM-DD')}`
                        : `toDate=${moment().endOf('year').format('YYYY-MM-DD')}`
                );
                break;

            case 'buuid':
                if (selectedBranches.length > 0) {
                    selectedBranches.forEach((br) => {
                        queryParts.push(`buuid=${br}`);
                    });
                }
                break;

            case 'key':
                if (forcedKey)
                    queryParts.push(`key=${forcedKey}`);
                else
                    queryParts.push(
                        `key=${activeSection === 'today' ? 'not-closed' : 'closed'}`
                    );

                break;

            case 'limit':
                if (queryKeys === 'topXItems' && topItemsLimit) {
                    queryParts.push(`limit=${topItemsLimit}`);
                }
                if (queryKeys === 'topXItemsAmt' && topItemsAmtLimit) {
                    queryParts.push(`limit=${topItemsAmtLimit}`);
                }
                if (queryKeys === 'feedbacksAndComplaints' && feedbacksAndComplaintsLimit) {
                    queryParts.push(`limit=${feedbacksAndComplaintsLimit}`);
                }
                if (queryKeys === 'feedbacksSummary' && feedbacksSummaryLimit) {
                    queryParts.push(`limit=${feedbacksSummaryLimit}`);
                }
                if (queryKeys === 'topItemsBranch' && topItemsBranchLimit) {
                    queryParts.push(`limit=${topItemsBranchLimit}`);
                }
                break;

            case 'order':
                if (order) queryParts.push(`order=${order}`);
                break;

            case 'years':
                if (years?.length > 0) {
                    years.forEach((year) => {
                        queryParts.push(`years=${year}`);
                    });
                }
                break;

            case 'amount':
                queryParts.push(`amount=${amount}`);
                break;

            case 'count':
                queryParts.push(`count=${count}`);
                break;

            default:
                break;
        }
    });

    filterString += queryParts.join('&');
    return filterString;
};



const actions = {
    setDashboardRest: (ruuid) => (dispatch) => {
        dispatch({
            type: SET_DASHBOARD_REST,
            payload: ruuid
        })
    },
    getNewProductsList: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_NEW_PRODUCTS_REQUEST
        })
        await getNewProductsList(ruuid, filterString).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_NEW_PRODUCTS_SUCCESS,
                    payload: response.data.newProducts
                })
            else handleError(dispatch)(response, GET_DASHBOARD_NEW_PRODUCTS_ERROR, 'GET DASHBOARD NEW PRODUCTS ERROR')
        })
    },
    getOrdersCountGreater: (ruuid, filterString, ordersBreakPoint) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_ORDERS_COUNT_REQUEST
        })
        await getCustomerCountOrder(ruuid, filterString += ordersBreakPoint).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_ORDERS_COUNT_SUCCESS,
                    payload: response.data.customersCount
                })
            else handleError(dispatch)(response, GET_DASHBOARD_ORDERS_COUNT_ERROR, 'GET DASHBOARD ORDERS COUNT ERROR')
        })
    },
    getOrdersValue: (ruuid, filterString, valueBreakpoint) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_ORDERS_VALUE_REQUEST
        })
        await getCustomerCountValue(ruuid, filterString += valueBreakpoint).then((response) => {
            if (response.status === 200)
                dispatch({
                    type: GET_DASHBOARD_ORDERS_VALUE_SUCCESS,
                    payload: response.data.customersCount
                })
            else handleError(dispatch)(response, GET_DASHBOARD_ORDERS_VALUE_ERROR, 'GET DASHBOARD ORDERS VALUE ERROR')
        })
    },
    getDashboardTotalRevenueTransactions: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_REQUEST
        })
        await getTotalRevenuePerMonth(ruuid, filterString).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_SUCCESS,
                    payload: response.data.revenueByMonthYear
                })
            } else handleError(dispatch)(response, GET_DASHBOARD_TOTAL_REVENUE_TRANSACTIONS_ERROR, 'GET  TOTAL NRVEENUE ERROR')
        })
    },
    getDashboardTotalRevenue: (ruuid, filterString) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_TOTAL_REVENUE_REQUEST
        })
        await getTotalRevenuePerMonth(ruuid, filterString).then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: GET_DASHBOARD_TOTAL_REVENUE_SUCCESS,
                    payload: response.data.revenueByMonthYear
                })
            } else handleError(dispatch)(response, GET_DASHBOARD_TOTAL_REVENUE_ERROR, 'GET TOTAL REVENUE ERROR')
        })
    },
    getDashboard: (
        ruuid,
        selectedBranches,
        filter,
        activeSection,
        topItemsLimit,
        topItemsAmtLimit,
        feedbacksAndComplaintsLimit,
        feedbacksSummaryLimit,
        years,
        amount,
        count
    ) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_REQUEST
        })
        try {
            const [
                topSpenders,
                avgOrderPrice,
                avgOrderPricePeriod,
                lateOrders,
                totalOrders,
                newCustomers,
                canceledOrders,
                salesByZone,
                // totalCustomers,
                salesByAffiliate,
                signupsByAffiliate,
                //NEW
                totalDiscounts,
                totalPromoDiscounts,
                topXItemsQty,
                leastXItemsQty,
                topXItemsAmount,
                leastXItemsAmount,
                feedbacksAndComplaints,
                feedbacksRating,
                notActiveCustomers,
                totalRevenuePerMonth,
                newProduct,
                customerCountValue,
                customersCount,
                totalOrdersByYear,
                qrCount,
                feedbacksSummaryPositive,
                feedbacksSummaryNegative,
                birthdayCount,
                deliveriesStatus,
                notSoldItems,
                ordersStatus,
                avgDeliveryTime,
                avgPrepTime
            ] = await Promise.all([
                getTopSpenders(ruuid, getFilterString(
                    'topSpenders',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getAvgOrderPrice(ruuid, getFilterString(
                    'avgOrderPrice',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                )),
                getAvgOrderPrice(ruuid, getFilterString(
                    'avgOrderPrice',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getLateOrders(ruuid, getFilterString(
                    'lateOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getNewCustomers(null, getFilterString(
                    'newCustomers',
                    null,
                    filter,
                    activeSection
                )),
                getCanceledOrders(ruuid, getFilterString(
                    'canceledOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getSalesByZone(ruuid, getFilterString(
                    'salesByZone',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                // getTotalCustomers(),
                getSalesByAffiliate(),
                getSignupsByAffiliate(),
                getTotalDiscounts(ruuid, getFilterString(
                    'totalDiscounts',
                    selectedBranches,
                    filter,
                    activeSection
                )),
                getTotalPromoDiscounts(ruuid, getFilterString(
                    'totalPromoDiscounts',
                    selectedBranches,
                    filter,
                )),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems',
                    selectedBranches,
                    filter,
                    activeSection,
                    topItemsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'DESC'
                )),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems',
                    selectedBranches,
                    filter,
                    activeSection,
                    topItemsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'ASC'
                )),
                getTopXItemsAmt(ruuid, getFilterString(
                    'topXItemsAmt',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    topItemsAmtLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'ASC'
                )),
                getTopXItemsAmt(ruuid, getFilterString(
                    'topXItemsAmt',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    topItemsAmtLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'DESC'
                )),
                getFeedbacksAndComplaints(ruuid, getFilterString(
                    'feedbacksAndComplaints',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    feedbacksAndComplaintsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'complaint',
                )),
                getFeedbacksRating(ruuid, getFilterString(
                    'feedbacksRating',
                    selectedBranches,
                    filter,
                    activeSection
                )),
                getNotActiveCustomers(ruuid, getFilterString(
                    'notActiveCustomers',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getTotalRevenuePerMonth(ruuid, getFilterString(
                    'totalRevenueMonth',
                    selectedBranches,
                    null,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    years,
                )),
                getNewProduct(ruuid, getFilterString(
                    'newProducts',
                    [],
                    filter,
                )),
                getCustomerCountValue(ruuid, getFilterString(
                    'customerCountValue',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    amount,
                )),
                getCustomerCountOrder(ruuid, getFilterString(
                    'customerCountOrder',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    count
                )),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                )),
                getQrCount(ruuid, getFilterString(
                    'qrScans',
                    selectedBranches,
                    filter,
                    activeSection
                )),
                getFeedbacksSummary(ruuid, getFilterString(
                    'feedbacksSummary',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    feedbacksSummaryLimit,
                    null,
                    null,
                    null,
                    null,
                    'feedback',
                )),
                getFeedbacksSummary(ruuid, getFilterString(
                    'feedbacksSummary',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    feedbacksSummaryLimit,
                    null,
                    null,
                    null,
                    null,
                    'complaint',
                )),
                getBirthdayCount(ruuid, getFilterString(
                    'birthdayCount',
                    [],
                    filter,
                )),
                getDeliveryStatus(ruuid, getFilterString(
                    'deliveryStatus',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getNotSoldItems(ruuid, getFilterString(
                    'notSoldItems',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getOrdersStatus(ruuid, getFilterString(
                    'ordersStatus',
                    selectedBranches,
                    filter
                )),
                getAvgDeliveryTime(ruuid, getFilterString(
                    'avgDelvTime',
                    selectedBranches,
                    filter,
                    activeSection
                )),
                getAvgPrepTime(ruuid, getFilterString(
                    'avgPrepTime',
                    selectedBranches,
                    filter,
                    activeSection
                ))
            ])
            const data = {
                topSpenders: topSpenders.data.topSpenders,
                avgOrderPrice: avgOrderPrice.data.avgBilledPrice,
                avgOrderPricePeriod: avgOrderPricePeriod.data.avgBilledPrice,
                lateOrders: lateOrders.data.lateOrders,
                totalOrders: totalOrders.data.orderCounts,
                newCustomers: newCustomers.data,
                canceledOrders: canceledOrders.data.canceledOrders,
                // totalCustomers: totalCustomers.data.totalCustomers,
                salesByZone: salesByZone.data,
                salesByAffiliate: salesByAffiliate.data.affiliateLinkSalesSummary,
                signupsByAffiliate: signupsByAffiliate.data.affiliateLinkSignUpsSummary,
                //NEW
                totalDiscounts: totalDiscounts.data.discounts,
                totalPromoDiscounts: totalPromoDiscounts.data.discounts,
                topXItemsQty: topXItemsQty.data.topItems,
                topXItemsAmount: topXItemsAmount.data.topItems,
                feedbacksAndComplaints: feedbacksAndComplaints.data.feedbacks,
                feedbacksRating: feedbacksRating.data,
                notActiveCustomers: notActiveCustomers.data.notActiveCustomersCount,
                totalRevenuePerMonth: totalRevenuePerMonth.data.revenueByMonthYear,
                totalRevenuePerMonthTransactions: totalRevenuePerMonth.data.revenueByMonthYear,
                newProduct: newProduct.data.newProductCount,
                leastXItemsQty: leastXItemsQty.data.topItems,
                leastXItemsAmount: leastXItemsAmount.data.topItems,
                customerCountValue: customerCountValue.data.customersCount,
                customersCount: customersCount.data.customersCount,
                totalOrdersByYear: totalOrdersByYear.data.orderCounts,
                qrCount: qrCount.data.qrCount,
                feedbacksSummaryPositive: feedbacksSummaryPositive.data.feedbacks,
                feedbacksSummaryNegative: feedbacksSummaryNegative.data.feedbacks,
                birthdayCount: birthdayCount.data.birthdayCount,
                deliveriesStatus: deliveriesStatus.data,
                notSoldItems: notSoldItems.data.notSoldItems,
                ordersStatus: ordersStatus.data.totalOrders,
                avgDeliveryTime: avgDeliveryTime.data.avg,
                avgPrepTime: avgPrepTime.data.avg
            }
            if (
                topSpenders.status !== 200 ||
                avgOrderPrice.status !== 200 ||
                avgOrderPricePeriod.status !== 200 ||
                lateOrders.status !== 200 ||
                totalOrders.status !== 200 ||
                newCustomers.status !== 200 ||
                canceledOrders.status !== 200 ||
                salesByZone.status !== 200 ||
                salesByAffiliate.status !== 200 ||
                signupsByAffiliate.status !== 200 ||
                totalDiscounts.status !== 200 ||
                totalPromoDiscounts.status !== 200 ||
                topXItemsQty.status !== 200 ||
                leastXItemsQty.status !== 200 ||
                topXItemsAmount.status !== 200 ||
                leastXItemsAmount.status !== 200 ||
                feedbacksAndComplaints.status !== 200 ||
                feedbacksRating.status !== 200 ||
                notActiveCustomers.status !== 200 ||
                totalRevenuePerMonth.status !== 200 ||
                newProduct.status !== 200 ||
                customerCountValue.status !== 200 ||
                customersCount.status !== 200 ||
                totalOrdersByYear.status !== 200 ||
                qrCount.status !== 200 ||
                feedbacksSummaryPositive.status !== 200 ||
                feedbacksSummaryNegative.status !== 200 ||
                birthdayCount.status !== 200 ||
                deliveriesStatus.status !== 200 ||
                notSoldItems.status !== 200 ||
                ordersStatus.status !== 200 ||
                avgDeliveryTime.status !== 200 ||
                avgPrepTime.status !== 200
            ) {
                dispatch({
                    type: GET_DASHBOARD_ERROR
                })
            }
            else
                dispatch({
                    type: GET_DASHBOARD_SUCCESS,
                    payload: data
                })
        } catch (e) {
            dispatch({
                type: GET_DASHBOARD_ERROR
            })
        }
    },
    getDashboardTransactions: (
        ruuid,
        selectedBranches,
        filter,
        activeSection,
        topItemsLimit,
        years,
    ) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_TRANSACTION_REQUEST
        })
        try {
            const [
                topSpenders,
                avgOrderPrice,
                avgOrderPricePeriod,
                lateOrders,
                totalOrders,
                canceledOrders,
                salesByZone,
                salesByAffiliate,
                signupsByAffiliate,
                //NEW
                totalDiscounts,
                totalPromoDiscounts,
                topXItemsQty,
                totalRevenuePerMonth,
                totalOrdersByYear,
                qrCount,
                deliveriesStatus,
                salesByRestaurant,
                salesByBranch,
                affiliateClicks,
                avgOrders,
                avgOrdersYear,
                salesByCategory,
                salesByCustomer,
                avgDeliveryTime,
                avgPrepTime
            ] = await Promise.all([
                getTopSpenders(ruuid, getFilterString(
                    'topSpenders',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getAvgOrderPrice(ruuid, getFilterString(
                    'avgOrderPrice',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                )),
                getAvgOrderPrice(ruuid, getFilterString(
                    'avgOrderPrice',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getLateOrders(ruuid, getFilterString(
                    'lateOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getCanceledOrders(ruuid, getFilterString(
                    'canceledOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getSalesByZone(ruuid, getFilterString(
                    'salesByZone',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                // getTotalCustomers(),
                getSalesByAffiliate(),
                getSignupsByAffiliate(),
                getTotalDiscounts(ruuid, getFilterString(
                    'totalDiscounts',
                    selectedBranches,
                    filter,
                    activeSection
                )),
                getTotalPromoDiscounts(ruuid, getFilterString(
                    'totalPromoDiscounts',
                    selectedBranches,
                    filter,
                )),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems',
                    selectedBranches,
                    filter,
                    activeSection,
                    topItemsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'DESC'
                )),
                getTotalRevenuePerMonth(ruuid, getFilterString(
                    'totalRevenueMonth',
                    selectedBranches,
                    null,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    years,
                )),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                )),
                getQrCount(ruuid, getFilterString(
                    'qrScans',
                    selectedBranches,
                    filter,
                    activeSection
                )),
                getDeliveryStatus(ruuid, getFilterString(
                    'deliveryStatus',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getSalesByRestaurant(getFilterString(
                    'salesByRestaurant',
                    null,
                    filter,
                )),
                getSalesByBranch(getFilterString(
                    'salesByBranch',
                    null,
                    filter,
                )),
                getAffiliateClicks(),
                getAvgOrders(ruuid, getFilterString(
                    'avgOrders',
                    selectedBranches,
                    filter
                )),
                getAvgOrders(ruuid, getFilterString(
                    'avgOrders',
                    selectedBranches,
                    filter,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true
                )),
                getSalesByCategory(ruuid, getFilterString(
                    'salesByCategory',
                    null,
                    filter
                )),
                getSalesByCustomer(ruuid, getFilterString(
                    'salesByCategory',
                    null,
                    filter
                )),
                getAvgDeliveryTime(ruuid, getFilterString(
                    'avgDelvTime',
                    selectedBranches,
                    filter,
                    activeSection
                )),
                getAvgPrepTime(ruuid, getFilterString(
                    'avgPrepTime',
                    selectedBranches,
                    filter,
                    activeSection
                ))
            ])
            const data = {
                topSpenders: topSpenders.data.topSpenders,
                avgOrderPrice: avgOrderPrice.data.avgBilledPrice,
                avgOrderPricePeriod: avgOrderPricePeriod.data.avgBilledPrice,
                lateOrders: lateOrders.data.lateOrders,
                totalOrders: totalOrders.data.orderCounts,
                canceledOrders: canceledOrders.data.canceledOrders,
                // totalCustomers: totalCustomers.data.totalCustomers,
                salesByZone: salesByZone.data,
                salesByAffiliate: salesByAffiliate.data.affiliateLinkSalesSummary,
                signupsByAffiliate: signupsByAffiliate.data.affiliateLinkSignUpsSummary,
                //NEW
                totalDiscounts: totalDiscounts.data.discounts,
                totalPromoDiscounts: totalPromoDiscounts.data.discounts,
                topXItemsQty: topXItemsQty.data.topItems,
                totalRevenuePerMonth: totalRevenuePerMonth.data.revenueByMonthYear,
                totalRevenuePerMonthTransactions: totalRevenuePerMonth.data.revenueByMonthYear,
                totalOrdersByYear: totalOrdersByYear.data.orderCounts,
                qrCount: qrCount.data.qrCount,
                deliveriesStatus: deliveriesStatus.data,
                salesByRestaurant: salesByRestaurant.data.restaurant,
                salesByBranch: salesByBranch.data.branch,
                affiliateClicks: affiliateClicks.data.affiliateLinks,
                avgOrders: avgOrders.data.avg,
                avgOrdersYear: avgOrdersYear.data.avg,
                salesByCategory: salesByCategory.data.salesByCategory,
                salesByCustomer: salesByCustomer.data.customers,
                avgDeliveryTime: avgDeliveryTime.data.avg,
                avgPrepTime: avgPrepTime.data.avg
            }
            if (
                topSpenders.status !== 200 ||
                avgOrderPrice.status !== 200 ||
                avgOrderPricePeriod.status !== 200 ||
                lateOrders.status !== 200 ||
                totalOrders.status !== 200 ||
                canceledOrders.status !== 200 ||
                salesByZone.status !== 200 ||
                salesByAffiliate.status !== 200 ||
                signupsByAffiliate.status !== 200 ||
                totalDiscounts.status !== 200 ||
                totalPromoDiscounts.status !== 200 ||
                topXItemsQty.status !== 200 ||
                totalRevenuePerMonth.status !== 200 ||
                totalOrdersByYear.status !== 200 ||
                qrCount.status !== 200 ||
                deliveriesStatus.status !== 200 ||
                salesByRestaurant.status !== 200 ||
                salesByBranch.status !== 200 ||
                affiliateClicks.status !== 200 ||
                avgOrders.status !== 200 ||
                avgOrdersYear.status !== 200 ||
                salesByCategory.status !== 200 ||
                salesByCustomer.status !== 200 ||
                avgDeliveryTime.status !== 200 ||
                avgPrepTime.status !== 200
            ) {
                dispatch({
                    type: GET_DASHBOARD_TRANSACTION_ERROR
                })
            }
            else
                dispatch({
                    type: GET_DASHBOARD_TRANSACTION_SUCCESS,
                    payload: data
                })
        } catch (e) {
            dispatch({
                type: GET_DASHBOARD_ERROR
            })
        }
    },
    getDashboardProducts: (
        ruuid,
        selectedBranches,
        filter,
        activeSection,
        topItemsLimit,
        topItemsAmtLimit,
        years,
    ) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_PRODUCTS_REQUEST
        })
        try {
            const [
                topXItemsQty,
                leastXItemsQty,
                topXItemsAmount,
                leastXItemsAmount,
                totalRevenuePerMonth,
                newProduct,
                totalOrdersByYear,
                notSoldItems,
                topItemsBranch
            ] = await Promise.all([
                // getTotalCustomers(),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems',
                    selectedBranches,
                    filter,
                    activeSection,
                    topItemsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'DESC'
                )),
                getTopXItemsQty(ruuid, getFilterString(
                    'topXItems',
                    selectedBranches,
                    filter,
                    activeSection,
                    topItemsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'ASC'
                )),
                getTopXItemsAmt(ruuid, getFilterString(
                    'topXItemsAmt',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    topItemsAmtLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'ASC'
                )),
                getTopXItemsAmt(ruuid, getFilterString(
                    'topXItemsAmt',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    topItemsAmtLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'DESC'
                )),
                getTotalRevenuePerMonth(ruuid, getFilterString(
                    'totalRevenueMonth',
                    selectedBranches,
                    null,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    years,
                )),
                getNewProduct(ruuid, getFilterString(
                    'newProducts',
                    [],
                    filter,
                )),
                getTotalOrders(ruuid, getFilterString(
                    'totalOrders',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                )),
                getNotSoldItems(ruuid, getFilterString(
                    'notSoldItems',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getTopItemsBranch(ruuid, getFilterString(
                    'topItemsBranch',
                    selectedBranches,
                    filter,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    5
                ))
            ])
            const data = {
                //NEW
                topXItemsQty: topXItemsQty.data.topItems,
                topXItemsAmount: topXItemsAmount.data.topItems,
                totalRevenuePerMonth: totalRevenuePerMonth.data.revenueByMonthYear,
                totalRevenuePerMonthTransactions: totalRevenuePerMonth.data.revenueByMonthYear,
                newProduct: newProduct.data.newProductCount,
                leastXItemsQty: leastXItemsQty.data.topItems,
                leastXItemsAmount: leastXItemsAmount.data.topItems,
                totalOrdersByYear: totalOrdersByYear.data.orderCounts,
                notSoldItems: notSoldItems.data.notSoldItems,
                topItemsBranch: topItemsBranch.data.topItemsByBranch
            }
            if (
                topXItemsQty.status !== 200 ||
                leastXItemsQty.status !== 200 ||
                topXItemsAmount.status !== 200 ||
                leastXItemsAmount.status !== 200 ||
                totalRevenuePerMonth.status !== 200 ||
                newProduct.status !== 200 ||
                totalOrdersByYear.status !== 200 ||
                notSoldItems.status !== 200 ||
                topItemsBranch.status !== 200
            ) {
                dispatch({
                    type: GET_DASHBOARD_PRODUCTS_ERROR
                })
            }
            else
                dispatch({
                    type: GET_DASHBOARD_PRODUCTS_SUCCESS,
                    payload: data
                })
        } catch (e) {
            dispatch({
                type: GET_DASHBOARD_PRODUCTS_ERROR
            })
        }
    },
    getDashboardCustomers: (
        ruuid,
        selectedBranches,
        filter,
        activeSection,
        feedbacksAndComplaintsLimit,
        feedbacksSummaryLimit,
        amount,
        count
    ) => async (dispatch) => {
        dispatch({
            type: GET_DASHBOARD_CUSTOMERS_REQUEST
        })
        try {
            const [
                newCustomers,
                feedbacksAndComplaints,
                feedbacksRating,
                notActiveCustomers,
                customerCountValue,
                customersCount,
                feedbacksSummaryPositive,
                feedbacksSummaryNegative,
                birthdayCount,
                avgOrders,
                salesByCustomer
            ] = await Promise.all([
                getNewCustomers(null, getFilterString(
                    'newCustomers',
                    null,
                    filter,
                    activeSection
                )),
                // getTotalCustomers(),
                getFeedbacksAndComplaints(ruuid, getFilterString(
                    'feedbacksAndComplaints',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    feedbacksAndComplaintsLimit,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'complaint',
                )),
                getFeedbacksRating(ruuid, getFilterString(
                    'feedbacksRating',
                    selectedBranches,
                    filter,
                    activeSection
                )),
                getNotActiveCustomers(ruuid, getFilterString(
                    'notActiveCustomers',
                    selectedBranches,
                    filter,
                    activeSection,
                )),
                getCustomerCountValue(ruuid, getFilterString(
                    'customerCountValue',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    amount,
                )),
                getCustomerCountOrder(ruuid, getFilterString(
                    'customerCountOrder',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    count
                )),
                getFeedbacksSummary(ruuid, getFilterString(
                    'feedbacksSummary',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    feedbacksSummaryLimit,
                    null,
                    null,
                    null,
                    null,
                    'feedback',
                )),
                getFeedbacksSummary(ruuid, getFilterString(
                    'feedbacksSummary',
                    selectedBranches,
                    filter,
                    activeSection,
                    null,
                    null,
                    null,
                    feedbacksSummaryLimit,
                    null,
                    null,
                    null,
                    null,
                    'complaint',
                )),
                getBirthdayCount(ruuid, getFilterString(
                    'birthdayCount',
                    [],
                    filter,
                )),
                getAvgOrders(ruuid, getFilterString(
                    'avgOrders',
                    selectedBranches,
                    filter
                )),
                getSalesByCustomer(ruuid, getFilterString(
                    'salesByCategory',
                    null,
                    filter
                ))
            ])
            const data = {
                newCustomers: newCustomers.data,
                //NEW
                feedbacksAndComplaints: feedbacksAndComplaints.data.feedbacks,
                feedbacksRating: feedbacksRating.data,
                notActiveCustomers: notActiveCustomers.data.notActiveCustomersCount,
                customerCountValue: customerCountValue.data.customersCount,
                customersCount: customersCount.data.customersCount,
                feedbacksSummaryPositive: feedbacksSummaryPositive.data.feedbacks,
                feedbacksSummaryNegative: feedbacksSummaryNegative.data.feedbacks,
                birthdayCount: birthdayCount.data.birthdayCount,
                avgOrders: avgOrders.data.avg,
                salesByCustomer: salesByCustomer.data.customers
            }
            if (
                newCustomers.status !== 200 ||
                feedbacksAndComplaints.status !== 200 ||
                feedbacksRating.status !== 200 ||
                notActiveCustomers.status !== 200 ||
                avgOrders.status !== 200 ||
                salesByCustomer.status !== 200
            ) {
                dispatch({
                    type: GET_DASHBOARD_CUSTOMERS_ERROR
                })
            }
            else
                dispatch({
                    type: GET_DASHBOARD_CUSTOMERS_SUCCESS,
                    payload: data
                })
        } catch (e) {
            dispatch({
                type: GET_DASHBOARD_CUSTOMERS_ERROR
            })
        }
    },
}
export default actions